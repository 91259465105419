import React, { useState, useEffect, useCallback } from 'react';
import Avatar from 'react-nice-avatar';
import { FaPlus, FaUserPlus, FaStar, FaFilter } from 'react-icons/fa';
import './Connect.css';
import { BASE_URL } from '../config';
import { io } from 'socket.io-client';

function Connect() {
    const [isPostFormVisible, setIsPostFormVisible] = useState(false);
    const [postDescription, setPostDescription] = useState('');
    const [activePost, setActivePost] = useState(null);
    const [nearbyUsers, setNearbyUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [friendRequestStatuses, setFriendRequestStatuses] = useState({});
    const [userType, setUserType] = useState('Traveller');
    const [userTypeFilter, setUserTypeFilter] = useState('All');
    const [socket, setSocket] = useState(null);


    const filteredNearbyUsers = nearbyUsers.filter(user => {
        if (userTypeFilter === 'All') return true;
        return user.activePost?.userType === userTypeFilter;
    });

    const handleUserTypeToggle = () => {
        setUserType(prevType => prevType === 'Traveller' ? 'Local' : 'Traveller');
      };

    const fetchCurrentUser = async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const userData = await response.json();
                setCurrentUser(userData);
                if (userData.activePost) {
                    const postResponse = await fetch(`${BASE_URL}/posts/${userData.activePost}`, {
                        method: 'GET',
                        credentials: 'include'
                    });
                    if (postResponse.ok) {
                        const postData = await postResponse.json();
                        setActivePost(postData);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching current user:', error);
        }
    };

    const fetchNearbyUsers = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/nearby-users`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                const usersWithRatings = data.map(user => ({
                    ...user,
                    numberOfRatings: user.ratings.length
                }));
                setNearbyUsers(usersWithRatings);
                fetchFriendRequestStatuses(usersWithRatings.map(user => user._id));
            }
        } catch (error) {
            console.error('Error fetching nearby users:', error);
        }
    }, []);    

    useEffect(() => {
        fetchCurrentUser();
        fetchNearbyUsers();
    }, [fetchNearbyUsers]);

    useEffect(() => {
        const newSocket = io(`${BASE_URL}`);
        setSocket(newSocket);

        return () => newSocket.close();
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('new post', () => {
                fetchNearbyUsers();
            });

            socket.on('post deleted', () => {
                fetchNearbyUsers();
            });
        }
    }, [socket, fetchNearbyUsers]);

    const fetchFriendRequestStatuses = async (userIds) => {
        try {
            const response = await fetch(`${BASE_URL}/friend-requests`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userIds }),
            });
            if (response.ok) {
                const statuses = await response.json();
                setFriendRequestStatuses(statuses);
            }
        } catch (error) {
            console.error('Error fetching friend request statuses:', error);
        }
    };

    const renderFriendButton = (userId) => {
        const status = friendRequestStatuses[userId];
        switch (status) {
            case 'accepted':
                return <span className="friend-status accepted">Added!</span>;
            case 'pending':
                return <span className="friend-status pending">Pending</span>;
            case 'rejected':
            default:
                return <button className="add-friend-button" onClick={() => sendFriendRequest(userId)}>
                    <FaUserPlus /> Add Friend
                </button>;
        }
    };

    const handleAddPost = async () => {
        if (postDescription.split(' ').length > 30) {
          alert('Post description should not exceed 30 words.');
          return;
        }
    
        try {
          const response = await fetch(`${BASE_URL}/posts`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ description: postDescription, userType }),
          });
          if (response.ok) {
            const newPost = await response.json();
            setActivePost(newPost);
            setPostDescription('');
            setIsPostFormVisible(false);
            fetchNearbyUsers();
            socket.emit('new post');
          }
        } catch (error) {
          console.error('Error adding post:', error);
        }
    };
    
    const renderStars = (rating, numberOfRatings) => {
        return (
            <div className="rating-container">
                {[...Array(5)].map((_, index) => (
                    <FaStar key={index} color={index < rating ? "#ffc107" : "#e4e5e9"} />
                ))}
                <span className="number-of-ratings">({numberOfRatings})</span>
            </div>
        );
    };
    
    

    const handleDeletePost = async () => {
        if (!activePost) {
            console.error('No active post to delete');
            return;
        }
        const postId = typeof activePost === 'object' ? activePost._id : activePost;
        try {
            const response = await fetch(`${BASE_URL}/posts/${postId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (response.ok) {
                setActivePost(null);
                fetchNearbyUsers();
                socket.emit('post deleted');
            } else {
                console.error('Failed to delete post:', await response.text());
            }
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const sendFriendRequest = async (userId) => {
        try {
            const response = await fetch(`${BASE_URL}/friend-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ recipientId: userId }),
            });
            if (response.ok) {
            
                setFriendRequestStatuses(prev => ({
                    ...prev,
                    [userId]: 'pending'
                }));
            }
        } catch (error) {
            console.error('Error sending friend request:', error);
        }
    };

    return (
        <div className="connect-container">
            {!activePost ? (
                <div 
                    className="your-post-box"
                    onClick={() => setIsPostFormVisible(true)}
                >
                    <div className="your-post-header">
                        <h3>Your Post</h3>
                        <FaPlus className="add-post-icon" />
                    </div>
                    <p className="click-to-post">Click here to create a new post</p>
                    <p className="click-to-post">(You will be seen and added by people around you)</p>
                </div>
            ) : (
                
                    <div className="your-post-header">
                        <h3>Your Active Post</h3>
                    </div>
                
            )}
    
            {(isPostFormVisible && !activePost) && (
                <div className="your-post-content">
                    <div className="post-form">
                        <textarea
                            value={postDescription}
                            onChange={(e) => setPostDescription(e.target.value)}
                            placeholder="Add a short description (max 30 words)..."
                        />
                        <div className="user-type-toggle">
                            <span className={`toggle-label ${userType === 'Traveller' ? 'active' : ''}`}>Traveller</span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={userType === 'Local'}
                                    onChange={handleUserTypeToggle}
                                />
                                <span className="slider round"></span>
                            </label>
                            <span className={`toggle-label ${userType === 'Local' ? 'active' : ''}`}>Local</span>
                        </div>
                        <div className="post-form-buttons">
                            <button onClick={() => setIsPostFormVisible(false)}>Cancel</button>
                            <button onClick={handleAddPost}>Post</button>
                        </div>
                    </div>
                </div>
            )}
    
            {activePost && currentUser && (
                <div className="your-post-content">
                    <div className="your-active-post">
                        <div className="user-info">
                            <Avatar className="user-avatar" {...currentUser.avatarConfig} />
                            <h4>{currentUser.fullName}</h4>
                        </div>
                        {activePost.description ? (
                            <p>{activePost.description}</p>
                        ) : (
                            <p>No description available</p>
                        )}
                        <button className="delete-post-button" onClick={handleDeletePost}>Delete Post</button>
                    </div>
                </div>
            )}
    
            <div className="people-around">
                <div className="people-around-header">
                    <h3>People Around You</h3>
                    <div className="user-type-filter">
                        <FaFilter />
                        <select 
                            value={userTypeFilter} 
                            onChange={(e) => setUserTypeFilter(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="Traveller">Traveller</option>
                            <option value="Local">Local</option>
                        </select>
                    </div>
                </div>
                <div className="user-list">
                    {filteredNearbyUsers.map(user => (
                        <div key={user._id} className="user-card">
                            <Avatar className="user-card-avatar" {...user.avatarConfig} />
                            <h4>{user.fullName}</h4>
                            <div className="user-rating">
                                {renderStars(user.averageRating, user.numberOfRatings)}
                            </div>
                            <p>{user.activePost?.description}</p>
                            <p>Posted as: {user.activePost?.userType}</p>
                            {renderFriendButton(user._id)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Connect;