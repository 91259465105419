import React, { useState, useCallback } from 'react';
import { FaArrowLeft, FaSpinner, FaTimes } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';
import { City } from 'country-state-city';
import './Explore.css';
import parisImage from '../Assets/paris.jpg'
import transylvaniaImage from '../Assets/romania.jpg';
import arcachonImage from '../Assets/arcachon.jpg';
import sloveniaImage from '../Assets/slovenia.jpg';
import llangollenCanalImage from '../Assets/canal.jpg';
import sibenikImage from '../Assets/sibenik.jpg';
import norfolkImage from '../Assets/norfolk.jpg';
import lasVegasImage from '../Assets/l.jpg';
import uImage from '../Assets/u.jpg';
import lehImage from '../Assets/le.jpg';


const suggestedPlaces = [
  "Paris, France",
  "Transylvania, Romania",
  "Arcachon, France",
  "Slovenia, Europe",
  "Llangollen Canal, Wales",
  "Sibenik and the Kornati Islands, Croatia",
  "Norfolk, United Kingdom",
  "Las Vegas, USA",
  "Udaipur, India",
  "Leh, India"
];

const placeImages = {
    "Paris, France": parisImage,
    "Transylvania, Romania": transylvaniaImage,
    "Arcachon, France": arcachonImage,
    "Slovenia, Europe": sloveniaImage,
    "Llangollen Canal, Wales": llangollenCanalImage,
    "Sibenik and the Kornati Islands, Croatia": sibenikImage,
    "Norfolk, United Kingdom": norfolkImage,
    "Las Vegas, USA": lasVegasImage,
    "Udaipur, India": uImage,
    "Leh, India": lehImage
  };

function Explore({ onBack, darkMode, genAI }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationInfo, setLocationInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showVR, setShowVR] = useState(false);
  const [vrChat, setVrChat] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const loadLocationOptions = useCallback((inputValue, callback) => {
    if (inputValue.length < 4) {
      callback([]);
      return;
    }

    const options = City.getAllCities()
      .filter(city => city.name.toLowerCase().includes(inputValue.toLowerCase()))
      .map(city => ({
        value: `${city.name}, ${city.stateCode}, ${city.countryCode}`,
        label: `${city.name}, ${city.stateCode}, ${city.countryCode}`
      }));

    callback(options.slice(0, 100));
  }, []);

  const handleLocationSelect = async (selectedOption) => {
    setSelectedLocation(selectedOption);
    setIsLoading(true);
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const prompt = `Give me tourist places to visit in ${selectedOption.label}. Also tell me the best month to visit there and about its history and culture.`;
      const result = await model.generateContent(prompt);
      const response = await result.response;
      setLocationInfo(formatResponse(response.text()));
      setShowPopup(true);
    } catch (error) {
      console.error('Error fetching location information:', error);
      setLocationInfo('Error fetching information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatResponse = (text) => {
    const cleanedText = text.replace(/\*\*/g, '');
    const sections = cleanedText.split('\n\n');
    return sections.map((section, index) => {
      const [title, ...content] = section.split('\n');
      return (
        <div key={index} className="info-section">
          <h3>{title}</h3>
          <ul>
            {content.map((item, i) => (
              <li key={i}>{item.replace(/^\*/, '')}</li>
            ))}
          </ul>
        </div>
      );
    });
  };

  const formatVRResponse = (text) => {
    const cleanedText = text.replace(/\*\*/g, '');
    const lines = cleanedText.split('\n');
    return (
      <div>
        {lines.map((line, index) => {
          if (line.startsWith('*')) {
            return <li key={index}>{line.replace('*', '')}</li>;
          } else if (line.toLowerCase().includes('current location:')) {
            return <h3 key={index}>{line}</h3>;
          } else {
            return <p key={index}>{line}</p>;
          }
        })}
      </div>
    );
  };

  const handleVRChat = async () => {
    setIsLoading(true);
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const prompt = `Give me a text based vr for ${selectedLocation.label}. Describe your surroundings and provide options with numbers for what you can do next, suggesting nearby places to explore based on user's current location. Don't choose options yourself, wait for the user's selection.`;
      const result = await model.generateContent(prompt);
      const response = await result.response;
      setVrChat([{ role: 'ai', content: formatVRResponse(response.text()) }]);
      setShowVR(true);
    } catch (error) {
      console.error('Error creating VR experience:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;

    const newChat = [...vrChat, { role: 'user', content: userMessage }];
    setVrChat(newChat);
    setUserMessage('');

    setIsLoading(true);
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const prompt = `Continue the VR experience for ${selectedLocation.label}. User input: ${userMessage}`;
      const result = await model.generateContent(prompt);
      const response = await result.response;
      setVrChat([...newChat, { role: 'ai', content: formatVRResponse(response.text()) }]);
    } catch (error) {
      console.error('Error in VR chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`explore-container ${darkMode ? 'dark-mode' : ''}`}>
      <div className="explore-header">
        <button className="back-button" onClick={onBack}>
          <FaArrowLeft /> Go Back
        </button>
        <h2>Explore Places</h2>
      </div>
      <AsyncSelect
        loadOptions={loadLocationOptions}
        onChange={handleLocationSelect}
        placeholder="Search for a city (min. 4 characters)"
        className="location-search"
        styles={{
          control: (provided, state) => ({
            ...provided,
            backgroundColor: darkMode ? '#3a3a3a' : 'white',
            borderColor: darkMode ? '#4a4a4a' : '#ced4da',
          }),
          input: (provided) => ({
            ...provided,
            color: darkMode ? 'white' : 'black',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: darkMode
              ? state.isFocused
                ? '#4a4a4a'
                : '#2a2a2a'
              : state.isFocused
              ? '#f0f0f0'
              : 'white',
            color: darkMode ? 'white' : 'black',
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: darkMode ? '#2a2a2a' : 'white',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: darkMode ? 'white' : 'black',
          }),
        }}
      />
      <div className="suggested-places">
        <h3>Suggested Places</h3>
        <div className="place-cards">
            {suggestedPlaces.map((place, index) => (
            <div key={index} className="place-card" onClick={() => handleLocationSelect({ value: place, label: place })}>
                <div className="place-image" style={{backgroundImage: `url(${placeImages[place]})`}}></div>
                <p>{place}</p>
            </div>
            ))}
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <FaSpinner className="loading-icon" />
        </div>
      )}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-button" onClick={() => setShowPopup(false)}><FaTimes /></button>
            <h2>{selectedLocation.label}</h2>
            <button className="vr-button" onClick={handleVRChat}>
              Explore in text-based VR
            </button>
            <div className="location-info">{locationInfo}</div>
          </div>
        </div>
      )}
      {showVR && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-button" onClick={() => setShowVR(false)}><FaTimes /></button>
            <h2>VR Experience: {selectedLocation.label}</h2>
            <div className="vr-chat">
              {vrChat.map((message, index) => (
                <div key={index} className={`message ${message.role}`}>
                  {message.content}
                </div>
              ))}
              <div className="chat-input">
                <input
                  type="text"
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  placeholder="Type your message..."
                />
                <button onClick={handleSendMessage}>Send</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Explore;