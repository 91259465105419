import React from 'react';
import './loading.css'; 
import geminiLogo from '../Assets/Gemini.png'; 

function Loading() {
  return (
    <div className="loading-container">
      <div className="Loading">
        <header className="Loading-header">
          <p className="Loading-title">Raahi</p>
          <div className="Powered-by">
            <h4>Powered by</h4>
            <img src={geminiLogo} alt="Gemini" className="Gemini-logo" />
          </div>
        </header>
      </div>
    </div>
  );
}

export default Loading;
