import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import Chats from './Chats';
import Connect from './Connect';
import Contribute from './Contribute';
import './Social.css';

function Social({ onBack, genAI }) {
    const [activeTab, setActiveTab] = useState('chats');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'chats':
                return <Chats />;
            case 'connect':
                return <Connect />;
            case 'contribute':
                return <Contribute genAI={genAI}/>;
            default:
                return null;
        }
    };

    return (
        <div className="social-container">
            <div className="social-header">
                <button className="back-button" onClick={onBack}>
                    <FaArrowLeft />
                </button>
                <h2>Social</h2>
            </div>
            <div className="tabs">
                <button 
                    className={`tab ${activeTab === 'chats' ? 'active' : ''}`}
                    onClick={() => setActiveTab('chats')}
                >
                    Chats
                </button>
                <button 
                    className={`tab ${activeTab === 'connect' ? 'active' : ''}`}
                    onClick={() => setActiveTab('connect')}
                >
                    Connect
                </button>
                <button 
                    className={`tab ${activeTab === 'contribute' ? 'active' : ''}`}
                    onClick={() => setActiveTab('contribute')}
                >
                    Contribute
                </button>
            </div>
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
}

export default Social;