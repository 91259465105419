import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Loading from './Loading/loading.js';
import Login from './Login/login.js';
import Signup from './Sign up/signup.js'; 
import CompleteProfile from './Complete Profile/complete.js';
import Home from './Home/home.js';
import { SpeechProvider } from './SpeechContext';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function App() {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const initialLoadCompleted = localStorage.getItem('initialLoadCompleted');
    
    if (initialLoadCompleted) {
      setInitialLoadComplete(true);
      setDarkMode(true);
    } else {
      const timer = setTimeout(() => {
        setInitialLoadComplete(true);
        setDarkMode(true);
        localStorage.setItem('initialLoadCompleted', 'true');
        localStorage.setItem('darkMode', 'true');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Router>
      {!initialLoadComplete && !darkMode ? (
        <Loading />
      ) : (
        <SpeechProvider>
          <RouteTransitionWrapper />
        </SpeechProvider>
      )}
    </Router>
  );
}

function RouteTransitionWrapper() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Routes location={location}>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/complete-profile" element={<CompleteProfile />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
