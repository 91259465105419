import React, { useState, useEffect, useCallback } from 'react';
import { FaSpinner, FaSuitcase, FaCheckCircle, FaTimesCircle, FaArrowLeft, FaInfoCircle, FaTrash } from 'react-icons/fa';
import './SavedItinerary.css';
import { BASE_URL } from '../config';

function SavedItinerary({ onBack, genAI, GOOGLE_MAPS_API_KEY }) {
  const [itineraries, setItineraries] = useState([]);
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tripInfo, setTripInfo] = useState(null);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [showTripInfo, setShowTripInfo] = useState(false);
  const [showAddedToCalendar, setShowAddedToCalendar] = useState(false);

  const reverseGeocode = useCallback(async (latitude, longitude) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`);
      if (!response.ok) throw new Error('Failed to reverse geocode');
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setUserLocation(prevState => ({
          ...prevState,
          address: data.results[0].formatted_address
        }));
      } else {
        console.error('No results found in geocoding response:', data);
      }
    } catch (error) {
      console.error('Error in reverse geocoding:', error);
    }
  }, [GOOGLE_MAPS_API_KEY]);

  const fetchItineraries = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/itineraries`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch itineraries');
      const data = await response.json();
      setItineraries(data.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)));
    } catch (error) {
      console.error('Error fetching itineraries:', error);
    }
    setIsLoading(false);
  };

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          reverseGeocode(latitude, longitude);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  }, [reverseGeocode]); 

  useEffect(() => {
    fetchItineraries();
    getUserLocation();
  }, [getUserLocation]);  

  const handleItineraryClick = (itinerary) => {
    setSelectedItinerary(itinerary);
    setTripInfo(itinerary.tripInfo || null);
    setShowTripInfo(false);
  };

  const handleConfirmTrip = async () => {
    setIsLoading(true);
    try {
      const tripInfo = await generateTripInfo(selectedItinerary);
      const response = await fetch(`${BASE_URL}/api/itineraries/${selectedItinerary._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ 
          isConfirmed: true,
          tripInfo: tripInfo
        }),
      });
      if (!response.ok) throw new Error('Failed to confirm trip');
      const updatedItinerary = await response.json();

      setSelectedItinerary(updatedItinerary);
      setItineraries(itineraries.map(i => i._id === updatedItinerary._id ? updatedItinerary : i));
      setTripInfo(tripInfo);

      
      await addToGoogleCalendar(updatedItinerary);

      
      setShowAddedToCalendar(true);
      setTimeout(() => setShowAddedToCalendar(false), 3000);

      
      setShowTripInfo(true);
    } catch (error) {
      console.error('Error confirming trip:', error);
    }
    setIsLoading(false);
  };

  const generateTripInfo = async (itinerary) => {
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });
    const prompts = [
      `Provide detailed weather information for ${itinerary.locations.join(', ')} from ${new Date(itinerary.startDate).toLocaleDateString()} to ${new Date(itinerary.endDate).toLocaleDateString()}. Include temperature ranges, precipitation chances, and any weather alerts or warnings.`,
      `Based on the weather information, provide packing suggestions for a trip to ${itinerary.locations.join(', ')} from ${new Date(itinerary.startDate).toLocaleDateString()} to ${new Date(itinerary.endDate).toLocaleDateString()}.`,
      `Suggest eco-friendly traveling tips and transport options for a trip to ${itinerary.locations.join(', ')}.`,
      `List popular taxi apps used in ${itinerary.locations.join(', ')} along with their download links. Format each app name as a clickable link.`,
      `Calculate the carbon footprint for a direct flight from ${userLocation.address} to ${itinerary.locations[0]}. Include the flight distance, carbon footprint produced, and the number of trees required to offset that footprint in one year.`
    ];

    let tripInfo = '';
    for (const prompt of prompts) {
      const result = await model.generateContent(prompt);
      const response = await result.response;
      tripInfo += response.text() + '\n\n';
    }

    return tripInfo.trim();
  };

  const addToGoogleCalendar = async (itinerary) => {
    try {
      const response = await fetch(`${BASE_URL}/api/calendar/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          itineraryId: itinerary._id
        }),
      });
      if (!response.ok) throw new Error('Failed to add to Google Calendar');
      const data = await response.json();
      console.log('Added to Google Calendar:', data);
    } catch (error) {
      console.error('Error adding to Google Calendar:', error);
    }
  };

  const handleDeleteItinerary = async () => {
    if (window.confirm('Are you sure you want to delete this itinerary?')) {
      setIsLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/api/itineraries/${selectedItinerary._id}`, {
          method: 'DELETE',
          credentials: 'include'
        });
        if (!response.ok) throw new Error('Failed to delete itinerary');
        setItineraries(itineraries.filter(i => i._id !== selectedItinerary._id));
        setSelectedItinerary(null);
      } catch (error) {
        console.error('Error deleting itinerary:', error);
      }
      setIsLoading(false);
    }
  };

  const handleCancelTrip = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/itineraries/${selectedItinerary._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ 
          isConfirmed: false,
          tripInfo: null
        }),
      });
      if (!response.ok) throw new Error('Failed to cancel trip');
      const updatedItinerary = await response.json();
      setSelectedItinerary(updatedItinerary);
      setItineraries(itineraries.map(i => i._id === updatedItinerary._id ? updatedItinerary : i));
      setTripInfo(null);

      
      await removeFromGoogleCalendar(updatedItinerary);

    } catch (error) {
      console.error('Error canceling trip:', error);
    }
    setIsLoading(false);
  };

  const removeFromGoogleCalendar = async (itinerary) => {
    try {
      const response = await fetch(`${BASE_URL}/api/calendar/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          itineraryId: itinerary._id
        }),
      });
      if (!response.ok) throw new Error('Failed to remove from Google Calendar');
      const data = await response.json();
      console.log('Removed from Google Calendar:', data);
    } catch (error) {
      console.error('Error removing from Google Calendar:', error);
    }
  };

  const renderTripInfo = (info) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
 
    const cleanedInfo = info.replace(/\*\*/g, '');
    return cleanedInfo.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line.split(urlRegex).map((part, i) => 
          part.match(urlRegex) ? 
            <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a> : 
            part
        )}
        <br />
      </React.Fragment>
    ));
  };

  const toggleTripInfo = () => {
    setShowTripInfo(!showTripInfo);
  };

  const filteredItineraries = showConfirmed ? itineraries.filter(i => i.isConfirmed) : itineraries;

  return (
    <div className="saved-itinerary-container">
      <div className="emergency-header">
        <button className="back-button" onClick={onBack}>
          <FaArrowLeft />Go Back
        </button>
        <h2>Saved Itineraries</h2>
        <div></div>
      </div>
      <p className="google-warning2">Itineraries only gets added to calendar if user loged in by "Continue with google"</p>
      <div className="filter-container">
        <button onClick={() => setShowConfirmed(!showConfirmed)}>
          {showConfirmed ? 'Show All Trips' : 'Show Confirmed Trips'}
        </button>
      </div>
      {isLoading ? (
        <div className="loading-overlay">
          <FaSpinner className="spinner" />
          <p>Processing..</p>
        </div>
      ) : (
        <div className="itinerary-list">
          {filteredItineraries.map((itinerary) => (
            <div
              key={itinerary._id}
              className={`itinerary-item ${itinerary.isConfirmed ? 'confirmed' : ''}`}
              onClick={() => handleItineraryClick(itinerary)}
            >
              <h3>{itinerary.locations.join(', ')}</h3>
              <p>{new Date(itinerary.startDate).toLocaleDateString()} - {new Date(itinerary.endDate).toLocaleDateString()}</p>
              {itinerary.isConfirmed && <FaCheckCircle className="confirmed-icon" />}
            </div>
          ))}
        </div>
      )}
      {selectedItinerary && (
        <div className="itinerary-popup">
          <h3>{selectedItinerary.locations.join(', ')}</h3>
          <p>{new Date(selectedItinerary.startDate).toLocaleDateString()} - {new Date(selectedItinerary.endDate).toLocaleDateString()}</p>
          <div className="itinerary-content">{selectedItinerary.itinerary}</div>
          <div className="button-container">
            {!selectedItinerary.isConfirmed ? (
              <button onClick={handleConfirmTrip} disabled={isLoading}>
                <FaSuitcase /> Confirm Trip
              </button>
            ) : (
              <>
                <button onClick={handleCancelTrip} disabled={isLoading}>
                  <FaTimesCircle /> Cancel Trip
                </button>
                <button onClick={toggleTripInfo}>
                  <FaInfoCircle /> Essential Info
                </button>
              </>
            )}
            <button onClick={handleDeleteItinerary} disabled={isLoading} className="delete-button">
              <FaTrash /> Delete Itinerary
            </button>
            <button onClick={() => setSelectedItinerary(null)}>Close</button>
          </div>
        </div>
      )}
      {showTripInfo && tripInfo && (
        <div className="trip-info-popup">
          <div className="trip-info-content">
            <h4>Trip Information</h4>
            {renderTripInfo(tripInfo)}
            <button onClick={toggleTripInfo}>Close</button>
          </div>
        </div>
      )}
      {showAddedToCalendar && (
        <div className="added-to-calendar-message">
          Added to your Google Calendar!
        </div>
      )}
    </div>
  );
}

export default SavedItinerary;