import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FaCamera, FaSync } from 'react-icons/fa';
import './CameraCapture.css';

const CameraCapture = ({ onCapture, onClose }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [facingMode, setFacingMode] = useState('user');
  const [error, setError] = useState(null);

  const startCamera = useCallback(async () => {
    try {
      const constraints = {
        video: { facingMode: facingMode }
      };
      const videoStream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = videoStream;
      }
    } catch (err) {
      console.error("Error accessing camera:", err);
      setError("Unable to access the camera. Please make sure you've granted the necessary permissions.");
    }
  }, [facingMode]);

  const stopCamera = useCallback(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  }, []);

  useEffect(() => {
    startCamera();
    return stopCamera;
  }, [startCamera, stopCamera]);

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        const file = new File([blob], "camera_capture.jpg", { type: "image/jpeg" });
        onCapture(file);
      }, 'image/jpeg');
    }
  };

  const switchCamera = () => {
    stopCamera();
    setFacingMode(prevMode => prevMode === 'user' ? 'environment' : 'user');
  };

  const handleClose = () => {
    stopCamera();
    onClose();
  };

  return (
    <div className="camera-capture-overlay">
      <div className="camera-capture-container">
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <>
            <video ref={videoRef} autoPlay playsInline />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <div className="camera-controls">
              <button className="capture-button" onClick={captureImage}>
                <FaCamera />
              </button>
            </div>
            <button className="switch-camera-button" onClick={switchCamera}>
              <FaSync />
            </button>
          </>
        )}
        <button className="close-button" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default CameraCapture;