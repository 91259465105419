import React, { useState, useEffect, useRef, useCallback } from 'react';
import Avatar from 'react-nice-avatar';
import { FaPlus, FaChevronDown, FaChevronUp, FaSpinner, FaUsers, FaComments } from 'react-icons/fa';
import './Contribute.css';
import { BASE_URL } from '../config';
import { io } from 'socket.io-client';


function Contribute({genAI}) {
    const [isPostFormVisible, setIsPostFormVisible] = useState(false);
    const [postDescription, setPostDescription] = useState('');
    const [postLocation, setPostLocation] = useState('');
    const [postDate, setPostDate] = useState('');
    const [postTime, setPostTime] = useState('');
    const [topContributors, setTopContributors] = useState([]);
    const [contributions, setContributions] = useState([]);
    const [expandedContributors, setExpandedContributors] = useState(null);
    const [expandedMessages, setExpandedMessages] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [cityIssues, setCityIssues] = useState('');
    const [cityInput, setCityInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [currentUserPoints, setCurrentUserPoints] = useState(0);
    const messagesEndRef = useRef(null);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = io(BASE_URL);
        setSocket(newSocket);

        return () => newSocket.close();
    }, []);

    useEffect(() => {
        fetchTopContributors();
        getUserLocation();
        fetchCurrentUser();
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const formatCityIssues = (text) => {
        return text
            .replace(/\*\*/g, '') 
            .replace(/\*/g, '')  
            .replace(/:/g, ': ')  
            .split('\n')         
            .map(line => line.trim())
            .filter(line => line !== '')
            .join('\n');          
    };

    const fetchCurrentUser = async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const userData = await response.json();
                setCurrentUser(userData);
                setCurrentUserPoints(userData.contributionPoints);
            }
        } catch (error) {
            console.error('Error fetching current user:', error);
        }
    };

    const handleDeleteContribution = async (contributionId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/contributions/${contributionId}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            if (response.ok) {
                fetchContributions();
            }
        } catch (error) {
            console.error('Error deleting contribution:', error);
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const fetchTopContributors = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/top-contributors`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setTopContributors(data);
            }
        } catch (error) {
            console.error('Error fetching top contributors:', error);
        }
    };

    const fetchContributions = useCallback(async () => {
        if (!userLocation) return;
        
        try {
            const response = await fetch(`${BASE_URL}/api/contributions?latitude=${userLocation.latitude}&longitude=${userLocation.longitude}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setContributions(data);
            }
        } catch (error) {
            console.error('Error fetching contributions:', error);
        }
    }, [userLocation]); 
    
    useEffect(() => {
        if (userLocation) {
            fetchContributions();
        }
    }, [userLocation, fetchContributions]);

    const handleAddContribution = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/contributions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    description: postDescription,
                    locationText: postLocation,
                    date: postDate,
                    time: postTime
                }),
            });
            if (response.ok) {
                setIsPostFormVisible(false);
                setPostDescription('');
                setPostLocation('');
                setPostDate('');
                setPostTime('');
                fetchContributions(); 
            }
        } catch (error) {
            console.error('Error adding contribution:', error);
        }
    };

    const handleContribute = async (contributionId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/contribute/${contributionId}`, {
                method: 'POST',
                credentials: 'include'
            });
            if (response.ok) {
                setContributions(contributions.map(contribution => 
                    contribution._id === contributionId
                        ? { ...contribution, contributors: [...contribution.contributors, currentUser] }
                        : contribution
                ));
                if (socket) {
                    socket.emit('new contributor', { contributionId, user: currentUser });
                }
            }
        } catch (error) {
            console.error('Error contributing:', error);
        }
    };

    const cancelContribution = async (contributionId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/cancel-contribution/${contributionId}`, {
                method: 'POST',
                credentials: 'include'
            });
            if (response.ok) {
                setContributions(contributions.map(contribution => 
                    contribution._id === contributionId
                        ? { ...contribution, contributors: contribution.contributors.filter(c => c._id !== currentUser._id) }
                        : contribution
                ));
                if (socket) {
                    socket.emit('cancel contribution', { contributionId, userId: currentUser._id });
                }
            }
        } catch (error) {
            console.error('Error cancelling contribution:', error);
        }
    };

    const fetchCityIssues = async () => {
        setLoading(true);
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const prompt = `What are the main socioeconomic and climate issues in ${cityInput}? Provide a brief summary.`;
            const result = await model.generateContent(prompt);
            const response = await result.response;
            const text = response.text();
            setCityIssues(formatCityIssues(text));
        } catch (error) {
            console.error('Error fetching city issues:', error);
            setCityIssues('An error occurred while fetching city issues.');
        } finally {
            setLoading(false);
        }
    };

    const fetchMessages = async (contributionId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/contributions/${contributionId}/messages`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setMessages(prevMessages => ({
                    ...prevMessages,
                    [contributionId]: data
                }));
                if (socket) {
                    socket.emit('join chat', contributionId);
                }
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const sendMessage = async (contributionId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/contributions/${contributionId}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ content: newMessage }),
            });
            if (response.ok) {
                const data = await response.json();
                setMessages(prevMessages => ({
                    ...prevMessages,
                    [contributionId]: [...(prevMessages[contributionId] || []), data]
                }));
                setNewMessage('');
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    useEffect(() => {
        if (socket) {
            socket.on('new message', (message) => {
                setMessages(prevMessages => ({
                    ...prevMessages,
                    [message.contributionId]: [...(prevMessages[message.contributionId] || []), message]
                }));
            });
    
            socket.on('contributor update', ({ contributionId, contributors }) => {
                setContributions(prevContributions => 
                    prevContributions.map(contribution => 
                        contribution._id === contributionId 
                            ? { ...contribution, contributors } 
                            : contribution
                    )
                );
            });
    
            return () => {
                socket.off('new message');
                socket.off('contributor update');
            };
        }
    }, [socket]);

    return (
        <div className="contribute-container">
            <div className="city-issues">
                <input
                    type="text"
                    value={cityInput}
                    onChange={(e) => setCityInput(e.target.value)}
                    placeholder="Enter city name"
                />
                <button onClick={fetchCityIssues} disabled={loading}>
                    {loading ? <FaSpinner className="spinner4" /> : 'Get City Issues'}
                </button>
                {cityIssues && (
                    <div className="issues-result">
                        <h3>City Issues:</h3>
                        <p>{cityIssues}</p>
                    </div>
                )}
            </div>
            <div className="top-contributors">
                <h3>Top Contributors This Month</h3>
                <div className="contributors-list">
                    {topContributors.map((contributor) => (
                        <div key={contributor._id} className="contributor">
                            <Avatar className="contributor-avatar" {...contributor.avatarConfig} />
                            <span>{contributor.fullName}</span>
                            <span className="points">{contributor.contributionPoints} points</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="my-points">
                <h3>My Points</h3>
                <div className="points-display">
                    <Avatar className="user-avatar" {...(currentUser?.avatarConfig || {})} />
                    <span>{currentUser?.fullName || 'Loading...'}</span>
                    <span className="points">{currentUserPoints} points</span>
                </div>
            </div>
            <div className="contribution-posts">
                <div className="your-post-box" onClick={() => setIsPostFormVisible(true)}>
                    <h3>Contributions</h3>
                    <FaPlus className="add-post-icon" />
                    <p className="click-to-post">Click here to create a new post</p>
                    <p className="click-to-post">You will gain points for creating or joining</p>
                    <p className="click-to-post">(People around you can contribute for a cause)</p>
                </div>
                {isPostFormVisible && (
                    <div className="post-form">
                        <textarea
                            value={postDescription}
                            onChange={(e) => setPostDescription(e.target.value)}
                            placeholder="Description"
                        />
                        <input
                            type="text"
                            value={postLocation}
                            onChange={(e) => setPostLocation(e.target.value)}
                            placeholder="Location"
                        />
                        <input
                            type="date"
                            value={postDate}
                            onChange={(e) => setPostDate(e.target.value)}
                        />
                        <input
                            type="time"
                            value={postTime}
                            onChange={(e) => setPostTime(e.target.value)}
                        />
                        <div className="post-form-buttons">
                            <button onClick={() => setIsPostFormVisible(false)}>Cancel</button>
                            <button onClick={handleAddContribution}>Post</button>
                        </div>
                    </div>
                )}
                <div className="contributions-list">
                    {contributions.map((contribution) => (
                        <div key={contribution._id} className="contribution-card">
                            <div className="contribution-header">
                                <Avatar className="user-avatar" {...contribution.creator.avatarConfig} />
                                <h4>{contribution.creator.fullName}</h4>
                                {currentUser && contribution.creator._id === currentUser._id && (
                                    <button 
                                        onClick={() => handleDeleteContribution(contribution._id)}
                                        className="delete-button"
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                            <p>{contribution.description}</p>
                            <p>Location: {contribution.locationText}</p>
                            <p>Date: {new Date(contribution.date).toLocaleDateString()}</p>
                            <p>Time: {contribution.time}</p>
                            {currentUser && contribution.creator._id !== currentUser._id && (
                                contribution.contributors.some(c => c._id === currentUser._id) ? (
                                    <button onClick={() => cancelContribution(contribution._id)} className="cancel-button">
                                        Cancel Registration
                                    </button>
                                ) : (
                                    <button 
                                        onClick={() => handleContribute(contribution._id)}
                                        className="contribute-button"
                                    >
                                        Contribute
                                    </button>
                                )
                            )}
                            <div className="dropdown-buttons">
                                <button
                                    className="expand-button"
                                    onClick={() => setExpandedContributors(expandedContributors === contribution._id ? null : contribution._id)}
                                >
                                    <FaUsers /> Contributors ({contribution.contributors.length})
                                    {expandedContributors === contribution._id ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                                <button
                                    className="expand-button"
                                    onClick={() => {
                                        setExpandedMessages(expandedMessages === contribution._id ? null : contribution._id);
                                        if (expandedMessages !== contribution._id) {
                                            fetchMessages(contribution._id);
                                        }
                                    }}
                                >
                                    <FaComments /> Messages
                                    {expandedMessages === contribution._id ? <FaChevronUp /> : <FaChevronDown />}
                                </button>
                            </div>
                            {expandedContributors === contribution._id && (
                                <div className="contributors-dropdown">
                                    {contribution.contributors.map((contributor) => (
                                        <div key={contributor._id} className="contributor">
                                            <Avatar className="contributor-avatar" {...contributor.avatarConfig} />
                                            <span>{contributor.fullName}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {expandedMessages === contribution._id && (
                                <div className="messages-dropdown">
                                    <div className="messages-list">
                                        {messages[contribution._id]?.map((message, index) => (
                                            <div key={index} className="message">
                                                <Avatar className="message-avatar" {...message.sender.avatarConfig} />
                                                <div className="message-content">
                                                    <span className="message-sender">{message.sender.fullName}</span>
                                                    <p>{message.content}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <div className="message-input">
                                        <input
                                            type="text"
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            onKeyPress={(e) => e.key === 'Enter' && sendMessage(contribution._id)}
                                            placeholder="Type a message..."
                                        />
                                        <button onClick={() => sendMessage(contribution._id)}>Send</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Contribute;