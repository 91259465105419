import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus, FaMinus, FaEdit, FaSave, FaArrowLeft, FaSpinner, FaHome } from 'react-icons/fa';
import './PlanItinerary.css';
import axios from 'axios';


function PlanItinerary({ onBack, genAI, GOOGLE_MAPS_API_KEY }) {
  const [step, setStep] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [locations, setLocations] = useState(['']);
  const [budget, setBudget] = useState({ amount: '', currency: 'USD' });
  const [accommodation, setAccommodation] = useState('');
  const [mealPreference, setMealPreference] = useState('');
  const [paceOfTravel, setPaceOfTravel] = useState('');
  const [outdoorPreferences, setOutdoorPreferences] = useState('');
  const [culturalInterests, setCulturalInterests] = useState([]);
  const [itinerary, setItinerary] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [itineraryId, setItineraryId] = useState(null);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [suggestedLocations, setSuggestedLocations] = useState([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [locationImages, setLocationImages] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  axios.defaults.baseURL = 'https://raahi.tech';
  axios.defaults.withCredentials = true;

  const progressPercentage = (step / 8) * 100;

  const reverseGeocode = useCallback(async (latitude, longitude) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`);
      if (!response.ok) throw new Error('Failed to reverse geocode');
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        console.error('No results found in geocoding response:', data);
        return null;
      }
    } catch (error) {
      console.error('Error in reverse geocoding:', error);
      return null;
    }
  }, [GOOGLE_MAPS_API_KEY]);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const address = await reverseGeocode(latitude, longitude);
          setUserLocation({ latitude, longitude, address });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  }, [reverseGeocode]);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  const handleNext = () => {
    setStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(prevStep => prevStep - 1);
    } else {
      onBack();
    }
  };

  const handleAddLocation = () => {
    setLocations([...locations, '']);
  };

  const handleRemoveLocation = (index) => {
    const newLocations = locations.filter((_, i) => i !== index);
    setLocations(newLocations);
  };

  const handleLocationChange = (index, value) => {
    const newLocations = [...locations];
    newLocations[index] = value;
    setLocations(newLocations);
  };

  const handleCulturalInterestToggle = (interest) => {
    setCulturalInterests(prevInterests =>
      prevInterests.includes(interest)
        ? prevInterests.filter(i => i !== interest)
        : [...prevInterests, interest]
    );
  };

  

  const suggestEcoFriendlyLocations = async () => {
    setIsLoadingSuggestions(true);
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const prompt = `Suggest 5 eco-friendly cities to travel near ${userLocation.address}. For each location, provide the approximate distance from ${userLocation.address} and a brief description of its eco-friendly features. Format the response as follows:
      
      City Name (approx distance from ${userLocation.address}): Brief description of eco-friendly features.
      
      Ensure each entry is on a new line and follows this exact format. Do not use asterisks or any other markdown formatting.`;
  
      const result = await model.generateContent(prompt);
      const response = await result.response;
      const suggestions = response.text().split('\n').filter(line => line.trim() !== '');
      setSuggestedLocations(suggestions);
  
      suggestions.forEach(suggestion => {
        const match = suggestion.match(/^(.*?)\s*\((.*?)\):(.*)/);
        if (match) {
          const [, cityName] = match;
          const cleanCityName = cityName.trim().replace(/^[-\s]+/, '');
          fetchPlacePhoto(cleanCityName);
        }
      });
    } catch (error) {
      console.error('Error suggesting eco-friendly locations:', error);
      setSuggestedLocations(['An error occurred while suggesting locations. Please try again.']);
    } finally {
      setIsLoadingSuggestions(false);
    }
  };
  
  const fetchPlacePhoto = async (cityName) => {
    try {
      const placeResponse = await axios.get(`/api/places/search?query=${encodeURIComponent(cityName)}`);
      if (!placeResponse.data.candidates || placeResponse.data.candidates.length === 0) {
        throw new Error('No place candidates found');
      }
      const placeId = placeResponse.data.candidates[0].place_id;
  
    
      const detailsResponse = await axios.get(`/api/places/details?place_id=${placeId}`);
      if (!detailsResponse.data.result || !detailsResponse.data.result.photos || detailsResponse.data.result.photos.length === 0) {
        throw new Error('No photos found for this place');
      }
      const photoReference = detailsResponse.data.result.photos[0].photo_reference;
  
      const photoUrl = `/api/places/photo?maxwidth=400&photoreference=${photoReference}`;
  
      setLocationImages(prevImages => {
        const newImages = {
          ...prevImages,
          [cityName]: photoUrl
        };
        return newImages;
      });
    } catch (error) {
      console.error(`Error fetching place photo for ${cityName}:`, error);
    }
  };

  const generateItinerary = async () => {
    const validation = validateFields();
    if (!validation.isValid) {
      setErrorMessage(`Please complete all entries in ${validation.stepName} to generate itinerary.`);
      return;
    }
  
    setErrorMessage('');
    setIsGenerating(true);
    try {
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const prompt = `Create a detailed travel itinerary for a trip with the following details:
        Start Date: ${startDate.toDateString()}
        End Date: ${endDate.toDateString()}
        Locations: ${locations.join(', ')}
        Budget: ${budget.amount} ${budget.currency}
        Accommodation Type: ${accommodation}
        Meal Preference: ${mealPreference}
        Pace of Travel: ${paceOfTravel}
        Outdoor Preferences: ${outdoorPreferences}
        Cultural Interests: ${culturalInterests.join(', ')}
        
        Please provide a day-by-day itinerary with suggested activities, restaurants, and attractions that match the preferences. Include estimated costs where possible. Do not use asterisks in the response.`;
  
      const result = await model.generateContent(prompt);
      const response = await result.response;
      let generatedItinerary = response.text();
      
      generatedItinerary = generatedItinerary.replace(/\*/g, '');
      
      setItinerary(generatedItinerary);
  
      const itineraryData = {
        startDate,
        endDate,
        locations,
        budget,
        accommodation,
        mealPreference,
        paceOfTravel,
        outdoorPreferences,
        culturalInterests,
        itinerary: generatedItinerary
      };
      const savedItinerary = await axios.post('/api/itineraries', itineraryData);
      setItineraryId(savedItinerary.data._id);
  
      setStep(9); 
    } catch (error) {
      console.error('Error generating itinerary:', error);
      setItinerary('An error occurred while generating the itinerary. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const validateFields = () => {
    if (startDate === null || endDate === null) {
      return { isValid: false, stepName: "Travel Dates" };
    }
    if (locations.length === 0 || locations.some(loc => loc.trim() === '')) {
      return { isValid: false, stepName: "Locations" };
    }
    if (budget.amount === '' || budget.currency === '') {
      return { isValid: false, stepName: "Approximate Budget" };
    }
    if (accommodation === '') {
      return { isValid: false, stepName: "Accommodation Type" };
    }
    if (mealPreference === '') {
      return { isValid: false, stepName: "Meal Preference" };
    }
    if (paceOfTravel === '') {
      return { isValid: false, stepName: "Pace of Travel" };
    }
    if (outdoorPreferences === '') {
      return { isValid: false, stepName: "Outdoor Preferences" };
    }
    if (culturalInterests.length === 0) {
      return { isValid: false, stepName: "Cultural Interests" };
    }
    return { isValid: true };
  };

  const handleEditItinerary = () => {
    setIsEditing(true);
  };

  const handleSaveItinerary = async () => {
    setIsEditing(false);
    try {
      await axios.put(`/api/itineraries/${itineraryId}`, { itinerary });
      setShowSaveMessage(true);
      setTimeout(() => {
        setShowSaveMessage(false);
      }, 2000);
    } catch (error) {
      console.error('Error saving itinerary:', error);
    }
  };

  const openInGoogleMaps = (cityName) => {
    const encodedLocation = encodeURIComponent(cityName);
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedLocation}`, '_blank');
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="step-content">
            <h3>Travel Dates</h3>
            <div className="date-picker-container">
              <div>
                <label>Start Date:</label>
                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
              </div>
              <div>
                <label>End Date:</label>
                <DatePicker selected={endDate} onChange={date => setEndDate(date)} minDate={startDate} />
              </div>
            </div>
          </div>
        );
        case 2:
          return (
            <div className="step-content">
              <h3>Locations</h3>
              {locations.map((location, index) => (
                <div key={index} className="location-input">
                  <input
                    value={location}
                    onChange={(e) => handleLocationChange(index, e.target.value)}
                    placeholder="Enter location"
                  />
                  {index > 0 && (
                    <button className="remove-button" onClick={() => handleRemoveLocation(index)}><FaMinus /></button>
                  )}
                </div>
              ))}
              <button className="add-button" onClick={handleAddLocation}><FaPlus /> Add Location</button>
              
              <button 
                className="suggest-button" 
                onClick={suggestEcoFriendlyLocations}
                disabled={!userLocation || isLoadingSuggestions}
              >
                {isLoadingSuggestions ? <FaSpinner className="spinner2" /> : null}
                Suggest Eco-Friendly Locations
              </button>
              
              {suggestedLocations.length > 0 && (
                <div className="suggested-locations">
                  <h4>Suggested Eco-Friendly Locations:</h4>
                  <ul className="location-list">
                    {suggestedLocations.map((location, index) => {
                      const match = location.match(/^(.*?)\s*\((.*?)\):(.*)/);
                      if (match) {
                        const [, cityName, distance, description] = match;
                        const cleanCityName = cityName.trim().replace(/^[-\s]+/, '');
                        return (
                          <li key={index} className="location-item">
                            <div className="location-info">
                              <button
                                onClick={() => openInGoogleMaps(cleanCityName)}
                                className="location-link"
                              >
                                {cleanCityName}
                              </button>
                              <span className="location-distance">{` (${distance})`}</span>
                              <p className="location-description">{description}</p>
                            </div>
                            {locationImages[cleanCityName] && (
                              <div className="location-image-container">
                                <img 
                                  src={`${axios.defaults.baseURL}${locationImages[cleanCityName]}`}
                                  alt={cleanCityName}
                                  className="location-image"
                                  onError={(e) => {
                                    console.error(`Error loading image for ${cleanCityName}:`, e);
                                    e.target.style.display = 'none';
                                  }}
                                />
                              </div>
                            )}
                          </li>
                        );
                      }
                      return <li key={index}>{location}</li>;
                    })}
                  </ul>
                </div>
              )}           
            </div>
          );
      case 3:
        return (
          <div className="step-content">
            <h3>Approximate Budget</h3>
            <div className="budget-input">
              <select value={budget.currency} onChange={(e) => setBudget({...budget, currency: e.target.value})}>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="JPY">JPY</option>
              </select>
              <input
                type="number"
                value={budget.amount}
                onChange={(e) => setBudget({...budget, amount: e.target.value})}
                placeholder="Enter amount"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="step-content">
            <h3>Accommodation Type</h3>
            <select value={accommodation} onChange={(e) => setAccommodation(e.target.value)}>
              <option value="">Select accommodation</option>
              <option value="hotel">Hotel</option>
              <option value="resort">Resort</option>
              <option value="villa">Villa</option>
              <option value="self-catered">Self-catered vacation rental</option>
            </select>
          </div>
        );
      case 5:
        return (
          <div className="step-content">
            <h3>Meal Preference</h3>
            <select value={mealPreference} onChange={(e) => setMealPreference(e.target.value)}>
              <option value="">Select meal preference</option>
              <option value="non-veg">Non-veg</option>
              <option value="veg">Veg</option>
              <option value="vegan">Vegan</option>
            </select>
          </div>
        );
      case 6:
        return (
          <div className="step-content">
            <h3>Pace of Travel</h3>
            <select value={paceOfTravel} onChange={(e) => setPaceOfTravel(e.target.value)}>
              <option value="">Select pace</option>
              <option value="slow">Slow</option>
              <option value="normal">Normal</option>
            </select>
          </div>
        );
      case 7:
        return (
          <div className="step-content">
            <h3>Outdoor Preferences</h3>
            <select value={outdoorPreferences} onChange={(e) => setOutdoorPreferences(e.target.value)}>
              <option value="">Select preference</option>
              <option value="adventure">Adventure</option>
              <option value="relaxed">Relaxed</option>
              <option value="nightlife">Night life</option>
            </select>
          </div>
        );
      case 8:
        return (
          <div className="step-content">
            <h3>Cultural Interests</h3>
            <div className="interest-buttons">
              {['historical sites', 'museums', 'local festivals', 'art galleries', 'food tours'].map(interest => (
                <button
                  key={interest}
                  onClick={() => handleCulturalInterestToggle(interest)}
                  className={culturalInterests.includes(interest) ? 'active' : ''}
                >
                  {interest}
                </button>
              ))}
            </div>
          </div>
        );
      case 9:
        return (
          <div className="itinerary-result">
            <h2>Your Custom Itinerary</h2>
            {isEditing ? (
              <textarea
                value={itinerary}
                onChange={(e) => setItinerary(e.target.value)}
              />
            ) : (
              <div className="itinerary-text">{itinerary}</div>
            )}
            <div className="itinerary-actions">
              <button onClick={handleEditItinerary}><FaEdit /> Edit</button>
              <button onClick={handleSaveItinerary}><FaSave /> Save</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="plan-itinerary-container">
      <button className="home-button" onClick={onBack}>
        <FaHome /> Back to Home
      </button>
      
      <button className="back-button" onClick={handleBack}>
        <FaArrowLeft /> Back
      </button>

      <div className="progress-bar-container">
        <div className="progress-bar" style={{width: `${progressPercentage}%`}}></div>
      </div>
      {showSaveMessage && (
        <div className="save-message">Saved!</div>
      )}
      {step <= 8 ? (
        <>
          {renderStep()}
          <div className="navigation-buttons">
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {step < 8 ? (
              <button onClick={handleNext}>Continue</button>
            ) : (
              <button onClick={generateItinerary} disabled={isGenerating}>
                {isGenerating ? <><FaSpinner className="spinner2" /> Generating...</> : 'Generate Itinerary'}
              </button>
            )}
          </div>
        </>
      ) : (
        renderStep()
      )}
    </div>
  );
}

export default PlanItinerary;