import React, { useState, useEffect } from 'react';
import { FaPlus, FaCamera, FaSpinner, FaPen, FaArrowLeft, FaCalendarAlt, FaImage, FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { BASE_URL } from '../config';
import CameraCapture from './CameraCapture';
import './Journal.css';


function Journal({ onBack, genAI }) {
    const [showJournalForm, setShowJournalForm] = useState(false);
    const [journalTitle, setJournalTitle] = useState('');
    const [journalContent, setJournalContent] = useState('');
    const [savedJournals, setSavedJournals] = useState([]);
    const [showCamera, setShowCamera] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedJournal, setSelectedJournal] = useState(null);
    const [generatedContent, setGeneratedContent] = useState('');
    const [itineraries, setItineraries] = useState([]);
    const [showItineraries, setShowItineraries] = useState(false);
    const [attachedImages, setAttachedImages] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginatedContent, setPaginatedContent] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const wordsPerPage = 200;

    useEffect(() => {
        fetchSavedJournals();
        fetchItineraries();
    }, []);

    const fetchSavedJournals = async () => {
        try {
            const response = await fetch(`${BASE_URL}/journals`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                const sortedJournals = data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setSavedJournals(sortedJournals);
            }
        } catch (error) {
            console.error('Error fetching saved journals:', error);
        }
    };

    const handleDeleteJournal = async () => {
        if (window.confirm('Are you sure you want to delete this journal entry?')) {
          try {
            const response = await fetch(`${BASE_URL}/journals/${selectedJournal._id}`, {
              method: 'DELETE',
              credentials: 'include'
            });
            if (response.ok) {
              setShowPopup(false);
              setSelectedJournal(null);
              fetchSavedJournals(); 
            } else {
              console.error('Failed to delete journal');
            }
          } catch (error) {
            console.error('Error deleting journal:', error);
          }
        }
    };

    const fetchItineraries = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/itineraries`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setItineraries(data);
            }
        } catch (error) {
            console.error('Error fetching itineraries:', error);
        }
    };

    const handleAddJournal = () => {
        setShowJournalForm(true);
    };

    const handleSaveJournal = async () => {
        if (!journalTitle.trim()) {
            alert('Please enter a title for your journal entry.');
            return;
        }
    
        if (attachedImages.length > 5) {
            alert('Only 5 photos are allowed per journal entry.');
            return;
        }
        setIsSaving(true);
        try {
            const formData = new FormData();
            formData.append('title', journalTitle);
            formData.append('content', journalContent);
            attachedImages.forEach((image, index) => {
                formData.append(`images`, image, `image_${index}`);
            });
    
            const url = selectedJournal ? `${BASE_URL}/journals/${selectedJournal._id}` : `${BASE_URL}/journals`;
            const method = selectedJournal ? 'PUT' : 'POST';
    
            const response = await fetch(url, {
                method: method,
                credentials: 'include',
                body: formData,
            });
    
            if (response.ok) {
                setShowJournalForm(false);
                setJournalTitle('');
                setJournalContent('');
                setAttachedImages([]);
                setSelectedJournal(null);
                fetchSavedJournals();
            } else {
                const errorData = await response.json();
                alert(`Error saving journal: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error saving journal:', error);
            alert('Error saving journal. Please try again.');
        }finally {
            setIsSaving(false); 
        }
    };

    const handleEnhanceContent = async () => {
        setIsLoading(true);
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const prompt = `Enhance the following journal entry: "${journalContent}"`;
            const result = await model.generateContent(prompt);
            const response = await result.response;
            const enhancedContent = response.text();
            setGeneratedContent(enhancedContent);
        } catch (error) {
            console.error('Error enhancing content:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCameraCapture = () => {
        setShowCamera(true);
    };

    const handleCapturedImage = async (file) => {
        setShowCamera(false);
        setIsLoading(true);
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
            const imageData = await fileToGenerativePart(file);
            const prompt = "Create a journal entry based on this image.";
            const result = await model.generateContent([prompt, imageData]);
            const response = await result.response;
            const generatedContent = response.text();
            setGeneratedContent(generatedContent);
            setAttachedImages(prevImages => [...prevImages, file]);
        } catch (error) {
            console.error('Error processing image:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fileToGenerativePart = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve({
                    inlineData: {
                        data: reader.result.split(',')[1],
                        mimeType: file.type,
                    },
                });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const splitContentIntoPages = (content) => {
        const words = content.split(' ');
        const pages = [];
        for (let i = 0; i < words.length; i += wordsPerPage) {
            pages.push(words.slice(i, i + wordsPerPage).join(' '));
        }
        return pages;
    };

    const handleJournalClick = async (journal) => {
        try {
            const response = await fetch(`${BASE_URL}/journals/${journal._id}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const journalData = await response.json();
                setSelectedJournal(journalData);
                const pages = splitContentIntoPages(journalData.content);
                setPaginatedContent(pages);
                setTotalPages(pages.length);
                setCurrentPage(1);
                setShowPopup(true);
            }
        } catch (error) {
            console.error('Error fetching journal details:', error);
        }
    };

    const handleEditJournal = () => {
        setJournalTitle(selectedJournal.title);
        setJournalContent(selectedJournal.content);
        const imagePromises = selectedJournal.images.map(async (imageUrl) => {
            const response = await fetch(`${BASE_URL}${imageUrl}`);
            const blob = await response.blob();
            return new File([blob], imageUrl.split('/').pop(), { type: blob.type });
        });
        Promise.all(imagePromises).then((files) => {
            setAttachedImages(files);
        });
        setShowJournalForm(true);
        setShowPopup(false);
    };

    const handleAddItinerary = () => {
        setShowItineraries(true);
    };

    const handleAddToJournal = () => {
        setJournalContent(prevContent => prevContent + '\n\n' + generatedContent);
        setGeneratedContent(''); 
    };

    const handleItineraryClick = async (itinerary) => {
        setIsLoading(true);
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const prompt = `Create a journal entry based on this itinerary: ${itinerary.itinerary}`;
            const result = await model.generateContent(prompt);
            const response = await result.response;
            const generatedJournalEntry = response.text();
            setGeneratedContent(generatedJournalEntry);
        } catch (error) {
            console.error('Error processing itinerary:', error);
        } finally {
            setIsLoading(false);
            setShowItineraries(false);
        }
    };

    const handleAddPhoto = (event) => {
        const files = Array.from(event.target.files);
        const totalImages = attachedImages.length + files.length;
        if (totalImages > 5) {
            alert('Only 5 photos are allowed per journal entry.');
            return;
        }
        setAttachedImages(prevImages => [...prevImages, ...files]);
    };

    const removeAttachedImage = (index) => {
        setAttachedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const handleImageClick = (imageSrc) => {
        setPreviewImage(imageSrc);
    };

    const closeImagePreview = () => {
        setPreviewImage(null);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="journal-container">
            <button className="back-button" onClick={onBack}>
                <FaArrowLeft /> Back to Home
            </button>
            <div className="add-journal"  onClick={handleAddJournal}>
                <h3>Add New Journal</h3>
                <button className="add-journal-button">
                    <FaPlus />
                </button>
            </div>
            {showJournalForm && (
                <div className="journal-form">
                    <input
                        type="text"
                        placeholder="Title"
                        value={journalTitle}
                        onChange={(e) => setJournalTitle(e.target.value)}
                    />
                    <textarea
                        value={journalContent}
                        onChange={(e) => setJournalContent(e.target.value)}
                        placeholder="Write your journal entry..."
                    />
                    {generatedContent && (
                        <div className="generated-content">
                            <h4>Generated Content:</h4>
                            <p>{generatedContent}</p>
                            <button onClick={handleAddToJournal}>
                                Add to Journal
                            </button>
                        </div>
                    )}
                    {attachedImages.length > 0 && (
                        <div className="attached-images-grid">
                            {attachedImages.map((image, index) => (
                                <div key={index} className="image-thumbnail">
                                    <img src={URL.createObjectURL(image)} alt={`Attached ${index}`} />
                                    <button onClick={() => removeAttachedImage(index)}>X</button>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="journal-form-buttons">
                        <button onClick={() => setShowJournalForm(false)}>Cancel</button>
                        <button onClick={handleEnhanceContent}>Enhance with Gemini</button>
                        <button onClick={handleCameraCapture}>
                            <FaCamera /> Take Photo
                        </button>
                        <input
                            type="file"
                            id="photo-upload"
                            multiple
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleAddPhoto}
                        />
                        <button onClick={() => document.getElementById('photo-upload').click()}>
                            <FaImage /> Add Photo (Max 5)
                        </button>
                        <button onClick={handleAddItinerary}>
                            <FaCalendarAlt /> Add Itinerary
                        </button>
                        <button 
                            onClick={handleSaveJournal} 
                            disabled={isSaving}
                            className={isSaving ? 'saving-button' : ''}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                </div>
            )}
            <div className="saved-journals">
                <h3>Saved Journals</h3>
                {savedJournals.map((journal) => (
                    <div key={journal._id} className="journal-entry" onClick={() => handleJournalClick(journal)}>
                        <h4>{journal.title || 'Untitled'}</h4>
                        <p>{new Date(journal.date).toLocaleDateString()}</p>
                        {journal.images && journal.images.length > 0 && (
                            <div className="journal-entry-images">
                                {journal.images.slice(0, 3).map((image, index) => (
                                    <img
                                        key={index}
                                        src={`${BASE_URL}${image}`}
                                        alt={`Journal ${index}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleImageClick(`${BASE_URL}${image}`);
                                        }}
                                    />
                                ))}
                                {journal.images.length > 3 && <span>+{journal.images.length - 3} more</span>}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {showPopup && selectedJournal && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>{selectedJournal.title || 'Untitled'}</h3>
                        <p style={{ whiteSpace: 'pre-wrap' }}>{paginatedContent[currentPage - 1]}</p>
                        {selectedJournal.images && selectedJournal.images.length > 0 && (
                            <div className="popup-images">
                                {selectedJournal.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={`${BASE_URL}${image}`}
                                        alt={`Journal ${index}`}
                                        onClick={() => handleImageClick(`${BASE_URL}${image}`)}
                                    />
                                ))}
                            </div>
                        )}
                        <div className="pagination-controls">
                            <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                <FaChevronLeft /> Previous
                            </button>
                            <span>{currentPage} / {totalPages}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                Next <FaChevronRight />
                            </button>
                        </div>
                        <div className="popup-buttons">
                            <button onClick={handleEditJournal}>
                                <FaPen /> Edit
                            </button>
                            <button onClick={handleDeleteJournal} className="delete-button">
                                <FaTrash /> Delete
                            </button>
                            <button onClick={() => setShowPopup(false)}>Close</button>
                        </div>
                    </div>
                </div>
            )}
            {showItineraries && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Select an Itinerary</h3>
                        {itineraries.map((itinerary) => (
                            <div key={itinerary._id} className="itinerary-item" onClick={() => handleItineraryClick(itinerary)}>
                                <h4>{itinerary.locations.join(', ')}</h4>
                                <p>{new Date(itinerary.startDate).toLocaleDateString()} - {new Date(itinerary.endDate).toLocaleDateString()}</p>
                            </div>
                        ))}
                        <button onClick={() => setShowItineraries(false)}>Close</button>
                    </div>
                </div>
            )}
            {showCamera && (
                <CameraCapture
                    onCapture={handleCapturedImage}
                    onClose={() => setShowCamera(false)}
                />
            )}
            {isLoading && (
                <div className="loading-overlay">
                    <FaSpinner className="loading-icon" />
                </div>
            )}
            {previewImage && (
                <div className="image-preview-modal" onClick={closeImagePreview}>
                    <div className="image-preview-content">
                        <img src={previewImage} alt="Preview" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Journal;