import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaPlus, FaArrowLeft, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './PostEmergency.css';
import { BASE_URL } from '../config';
import Avatar from 'react-nice-avatar';
import io from 'socket.io-client';

const useReverseGeocode = (GOOGLE_MAPS_API_KEY) => {
    return useCallback(async (latitude, longitude) => {
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          return data.results[0].formatted_address;
        } else {
          console.error('No results found in geocoding response:', data);
          return 'Address not found';
        }
      } catch (error) {
        console.error('Error in reverse geocoding:', error);
        return 'Error fetching address';
      }
    }, [GOOGLE_MAPS_API_KEY]);
  };


function PostEmergency({ onBack, genAI, GOOGLE_MAPS_API_KEY }) {
    const [showPostForm, setShowPostForm] = useState(false);
    const [contactInfo, setContactInfo] = useState('');
    const [location, setLocation] = useState('Fetching location...');
    const [description, setDescription] = useState('');
    const [postedEmergencies, setPostedEmergencies] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [isPosting, setIsPosting] = useState(false);
    const [chatMessages, setChatMessages] = useState({});
    const [currentMessage, setCurrentMessage] = useState('');
    const [selectedEmergency, setSelectedEmergency] = useState(null);
    const [userData, setUserData] = useState(null);
    const [receivedMessages, setReceivedMessages] = useState({});
    const [expandedEmergencies, setExpandedEmergencies] = useState({});
    const [socket, setSocket] = useState(null);
    const [emergencyMessages, setEmergencyMessages] = useState({});
    const [geminiChatMessages, setGeminiChatMessages] = useState({});
    const chatContainerRef = useRef(null);
    const reverseGeocode = useReverseGeocode(GOOGLE_MAPS_API_KEY);

    const getUserLocation = useCallback(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setUserLocation({ latitude, longitude });
              reverseGeocode(latitude, longitude).then(setLocation);
            },
            (error) => {
              console.error('Error getting user location:', error);
              setLocation('Unable to fetch location');
            }
          );
        } else {
          setLocation('Geolocation is not supported by this browser');
        }
      }, [reverseGeocode]);

    useEffect(() => {
        const newSocket = io('http://localhost:7001', { withCredentials: true });
        setSocket(newSocket);

        return () => newSocket.close();
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('receive message', (message) => {
                setReceivedMessages(prev => ({
                    ...prev,
                    [message.emergencyId]: [...(prev[message.emergencyId] || []), message]
                }));
            });
        }
    }, [socket]);

    useEffect(() => {
        getUserLocation();
        fetchPostedEmergencies();
        fetchUserData();
    }, [getUserLocation]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [geminiChatMessages, receivedMessages]);

    useEffect(() => {
        postedEmergencies.forEach(async (emergency) => {
            try {
                const response = await fetch(`${BASE_URL}/api/emergency-messages/${emergency._id}`, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch messages');
                }
                const messages = await response.json();
                setEmergencyMessages(prev => ({ ...prev, [emergency._id]: messages }));
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        });
    }, [postedEmergencies]);

    useEffect(() => {
        if (socket && selectedEmergency) {
            socket.emit('join chat', selectedEmergency);
            return () => {
                socket.emit('leave chat', selectedEmergency);
            };
        }
    }, [socket, selectedEmergency]);

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setUserData(data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };


    const fetchPostedEmergencies = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/emergencies`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setPostedEmergencies(data);
        } catch (error) {
            console.error('Error fetching emergencies:', error);
        }
    };

    const getAISuggestion = async (emergencyDescription, location) => {
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });
        const prompt = `Given this emergency situation: "${emergencyDescription}" at location "${location}", what should the person do to save themselves? Please provide emergency contact numbers relevant to that situation, nearest hospital name and list of steps 3-4 steps. Format any phone numbers like this: [PHONE]number[/PHONE]. Don't give too long answer, keep it short and simple`;
        const result = await model.generateContent(prompt);
        const response = await result.response;
        return response.text()
            .replace(/\*/g, '')         
            .replace(/^\s*\d+\.\s*/gm, '')
            .replace(/\n{2,}/g, '\n')   
            .trim();                    
    };

    const formatPhoneNumbers = (text) => {
        return text.replace(/\[PHONE\](.*?)\[\/PHONE\]/g, (match, p1) => {
            return `<a href="tel:${p1}" class="phone-link">${p1}</a>`;
        });
    };

    const toggleExpand = (emergencyId) => {
        setExpandedEmergencies(prev => ({
            ...prev,
            [emergencyId]: !prev[emergencyId]
        }));
    };

    const handlePostEmergency = async () => {
        setIsPosting(true);
        try {
            const aiSuggestion = await getAISuggestion(description, location);
            const newEmergency = {
                contactInfo,
                location: {
                    type: 'Point',
                    coordinates: [userLocation.longitude, userLocation.latitude]
                },
                locationText: location,
                description,
                aiSuggestion
            };

            const response = await fetch(`${BASE_URL}/api/emergencies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(newEmergency),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const postedEmergency = await response.json();
            setPostedEmergencies([postedEmergency, ...postedEmergencies]);
            setChatMessages({
                ...chatMessages,
                [postedEmergency._id]: [{ role: 'ai', content: aiSuggestion }]
            });
            setShowPostForm(false);
            setContactInfo('');
            setDescription('');
            setSelectedEmergency(postedEmergency._id);
        } catch (error) {
            console.error('Error posting emergency:', error);
        } finally {
            setIsPosting(false);
        }
    };

    const handleDeleteEmergency = async (emergencyId) => {
        try {
            const response = await fetch(`${BASE_URL}/api/emergencies/${emergencyId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setPostedEmergencies(postedEmergencies.filter(e => e._id !== emergencyId));
            if (selectedEmergency === emergencyId) {
                setSelectedEmergency(null);
            }
        } catch (error) {
            console.error('Error deleting emergency:', error);
        }
    };

    // const sendMessage = async (emergencyId, message) => {
    //     try {
    //         const response = await fetch(`${BASE_URL}/api/send-emergency-message`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             credentials: 'include',
    //             body: JSON.stringify({ emergencyId, message }),
    //         });
    
    //         if (!response.ok) {
    //             throw new Error('Failed to send message');
    //         }
    
            
    //         socket.emit('new message', { emergencyId, message, user: userData });
    
          
    //         setEmergencyMessages(prev => ({
    //             ...prev,
    //             [emergencyId]: [...(prev[emergencyId] || []), { user: userData, message }]
    //         }));
    //     } catch (error) {
    //         console.error('Error sending message:', error);
    //     }
    // };

    const handleSendMessage = async () => {
        if (!currentMessage.trim() || !selectedEmergency) return;
    
        
        setGeminiChatMessages(prevMessages => ({
            ...prevMessages,
            [selectedEmergency]: [
                ...(prevMessages[selectedEmergency] || []),
                { role: 'user', content: currentMessage },
                { role: 'processing', content: 'Processing...' } 
            ]
        }));
    
       
        setCurrentMessage('');
    
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const emergency = postedEmergencies.find(e => e._id === selectedEmergency);
            const context = `Emergency situation: ${emergency.description}\nLocation: ${emergency.locationText}\nPrevious AI suggestion: ${emergency.aiSuggestion}\n\nUser question: ${currentMessage}`;
            const result = await model.generateContent(context);
            const response = await result.response;
            const formattedResponse = response.text()
                .replace(/\*/g, '')
                .replace(/^\s*\d+\.\s*/gm, '')
                .replace(/\n{2,}/g, '\n')
                .trim();
            
         
            setGeminiChatMessages(prevMessages => ({
                ...prevMessages,
                [selectedEmergency]: prevMessages[selectedEmergency].filter(msg => msg.role !== 'processing').concat({ role: 'ai', content: formattedResponse })
            }));
        } catch (error) {
            console.error('Error getting AI response:', error);
            
            setGeminiChatMessages(prevMessages => ({
                ...prevMessages,
                [selectedEmergency]: prevMessages[selectedEmergency].filter(msg => msg.role !== 'processing').concat({ role: 'error', content: 'Failed to get AI response. Please try again.' })
            }));
        }
    };

    return (
        <div className="post-emergency-container">
            <div className="emergency-header">
                <button className="back-button" onClick={onBack}>
                    <FaArrowLeft />Go Back
                </button>
                <h2>Post an Emergency</h2>
                <button className="add-emergency-button" onClick={() => setShowPostForm(true)}>
                    <FaPlus />
                </button>
            </div>
            {showPostForm && (
                <div className="emergency-form">
                    <input
                        type="text"
                        value={contactInfo}
                        onChange={(e) => setContactInfo(e.target.value)}
                        placeholder="Contact Info (optional)"
                    />
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Location"
                    />
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Describe your emergency"
                    />
                    <button onClick={handlePostEmergency} disabled={isPosting}>
                        {isPosting ? <div className="spinner"></div> : 'Post Emergency'}
                    </button>
                </div>
            )}
            <div className="posted-emergencies">
                {postedEmergencies.map((emergency) => (
                    <div key={emergency._id} className="emergency-card">
                        <div className="user-info">
                            <Avatar style={{ width: '60px', height: '60px' }} {...userData.avatarConfig} />
                            <span className="user-name">{emergency.user?.fullName || userData?.fullName}</span>
                        </div>
                        <p><strong>Contact:</strong> {emergency.contactInfo}</p>
                        <p><strong>Location:</strong> {emergency.locationText}</p>
                        <p><strong>Description:</strong> {emergency.description}</p>
                        <div className="ai-suggestion">
                            <h4>Gemini Suggestion:</h4>
                            {emergency.aiSuggestion.split('\n').map((suggestion, index) => (
                                <p key={index} dangerouslySetInnerHTML={{ __html: formatPhoneNumbers(suggestion) }} />
                            ))}
                        </div>
                        <button onClick={() => handleDeleteEmergency(emergency._id)} className="btn delete-btn">
                            <FaTrash /> Delete
                        </button>
                        <button onClick={() => setSelectedEmergency(emergency._id)} className="btn chat-ai-btn">
                            Chat with Gemini
                        </button>
                        {emergencyMessages[emergency._id] && emergencyMessages[emergency._id].length > 0 && (
                            <div className="received-messages-container">
                                <button onClick={() => toggleExpand(emergency._id)} className="toggle-messages-btn">
                                    {expandedEmergencies[emergency._id] ? <FaChevronUp /> : <FaChevronDown />}
                                    Messages ({emergencyMessages[emergency._id].length})
                                </button>
                                {expandedEmergencies[emergency._id] && (
                                    <div className="received-messages">
                                        {emergencyMessages[emergency._id].map((msg, index) => (
                                            <div key={index} className="message-with-avatar">
                                                <Avatar 
                                                    style={{ width: '30px', height: '30px' }} 
                                                    {...(msg.user.avatarConfig || {})} 
                                                />
                                                <div className="message-content">
                                                    <strong>{msg.user.fullName}:</strong> {msg.message}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {selectedEmergency && (
                <div className="chat-section">
                    <h3>AI Assistance Chat</h3>
                    <div className="chat-messages" ref={chatContainerRef}>
                        {geminiChatMessages[selectedEmergency]?.map((message, index) => (
                            <div key={index} className={`message ${message.role}`}>
                                {message.role === 'processing' ? (
                                    <div className="processing-message">Processing...</div>
                                ) : message.role === 'ai' ? (
                                    <div dangerouslySetInnerHTML={{ __html: formatPhoneNumbers(message.content) }} />
                                ) : (
                                    message.content
                                )}
                            </div>
                        ))}
                    </div>
                    <input
                        type="text"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        placeholder="Type your message..."
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    />
                    <button onClick={handleSendMessage} className="btn send-btn">Send</button>
                </div>
            )}
        </div>
    );
}

export default PostEmergency;