import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './signup.css';
import googleLogo from '../Assets/google.png';
import { BASE_URL } from '../config';
import { debounce } from 'lodash';
import Avatar, { genConfig } from 'react-nice-avatar';
import { FaSun, FaMoon, FaVolumeUp } from 'react-icons/fa';
import { useSpeechSynthesis } from 'react-speech-kit';
import { useSpeech } from '../SpeechContext';


function Signup() {
  const [formData, setFormData] = useState({
    fullname: '',
    gender: '',
    username: '',
    email: '',
    password: '',
    repassword: '',
  });

  const [avatarConfig, setAvatarConfig] = useState(null);
  const avatarConfigCache = useRef({});

  const [validation, setValidation] = useState({
    username: '',
    email: '',
    password: '',
    repassword: ''
  });

  const [touchedFields, setTouchedFields] = useState({});
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const navigate = useNavigate();

  const verificationInputs = useRef([]);

  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
  const { isSpeechEnabled, toggleSpeech } = useSpeech();
  const { speak } = useSpeechSynthesis();

  const handleToggleSpeech = () => {
    toggleSpeech();
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode.toString());
  };

  const generateAndCacheConfig = useCallback(() => {
    const { fullname, gender } = formData;
    const cacheKey = `${fullname}-${gender}`;
    if (fullname && gender && !avatarConfigCache.current[cacheKey]) {
      const config = genConfig({
        seed: fullname,
        hatStyle: 'none',
        sex: gender === 'Female' ? 'woman' : 'man',
        hairStyle: gender === 'Female' ? 'womanShort' : 'normal',
        shirtStyle: "polo",
      });
      avatarConfigCache.current[cacheKey] = config;
      setAvatarConfig(config);
    } else if (fullname && gender) {
      setAvatarConfig(avatarConfigCache.current[cacheKey]);
    } else {
      setAvatarConfig(null);
    }
  }, [formData]);

  useEffect(() => {
    generateAndCacheConfig();
  }, [formData.fullname, formData.gender, generateAndCacheConfig]);

  const debouncedUsernameCheck = useCallback((username) => {
    const checkUsername = async () => {
      try {
        const response = await fetch(`${BASE_URL}/check-username?username=${username}`);
        const data = await response.json();
        setValidation((prevValidation) => ({
          ...prevValidation,
          username: data.exists ? 'Username already exists' : ''
        }));
      } catch (error) {
        console.error('Error checking username:', error);
      }
    };

    debounce(checkUsername, 300)();
  }, []);

  const emailCheck = useCallback(async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a correct email';
    } else {
      try {
        const response = await fetch(`${BASE_URL}/check-email?email=${email}`);
        const data = await response.json();
        return data.exists ? 'This email already exists, please login' : '';
      } catch (error) {
        console.error('Error checking email:', error);
        return '';
      }
    }
  }, []);

  const passwordCheck = useCallback((password) => {
    if (password.length >= 12 &&
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /\d/.test(password) &&
        /[^A-Za-z0-9]/.test(password)) {
      return '';
    }
    
    let feedback = [];
    if (password.length < 6) feedback.push("at least 6 characters long");
    if (!/[A-Z]/.test(password)) feedback.push("an uppercase letter");
    if (!/[a-z]/.test(password)) feedback.push("a lowercase letter");
    if (!/\d/.test(password)) feedback.push("a number");
    if (!/[^A-Za-z0-9]/.test(password)) feedback.push("a special character");
    
    return feedback.length > 0 ? `Password must include ${feedback.join(", ")}` : '';
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'fullname' || name === 'gender') {
      generateAndCacheConfig();
    }

    if (name === 'username' && value) {
      debouncedUsernameCheck(value);
    } else if (name === 'email' && touchedFields.email) {
      emailCheck(value).then(message => {
        setValidation(prev => ({ ...prev, email: message }));
      });
    } else if (name === 'password' && touchedFields.password) {
      setValidation(prev => ({ ...prev, password: passwordCheck(value) }));
    } else if (name === 'repassword') {
      setValidation(prev => ({
        ...prev,
        repassword: value === formData.password ? '' : 'Passwords do not match'
      }));
    }
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));

    if (name === 'email') {
      const message = await emailCheck(value);
      setValidation(prev => ({ ...prev, email: message }));
    } else if (name === 'password') {
      setValidation(prev => ({ ...prev, password: passwordCheck(value) }));
    }
  };

  useEffect(() => {
    if (touchedFields.repassword && formData.repassword) {
      setValidation(prev => ({
        ...prev,
        repassword: formData.repassword === formData.password ? '' : 'Passwords do not match'
      }));
    }
  }, [formData.password, formData.repassword, touchedFields.repassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (formData.password !== formData.repassword) {
        alert('Passwords do not match');
        return;
      }

      if (!acceptedTerms) {
        alert('Please accept the Terms and Conditions and Privacy Policy');
        return;
      }

      if (validation.username || validation.email || validation.password || validation.repassword) {
        alert('Please fix the errors before submitting');
        return;
      }

      // const hometown = formData.hometown 
      //   ? `${formData.hometown}, ${Country.getCountryByCode(formData.country).name}`
      //   : Country.getCountryByCode(formData.country).name;

      const response = await fetch(`${BASE_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fullName: formData.fullname,
          gender: formData.gender,
          username: formData.username,
          email: formData.email,
          password: formData.password,
          avatarConfig: avatarConfig
        })
      });

      if (response.ok) {
        setIsVerifying(true);
      } else {
        const errorData = await response.text();
        alert(`Error registering user: ${errorData}`);
      }
    } catch (error) {
      alert('Error registering user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationCodeChange = (index, value) => {
    const newCode = verificationCode.split('');
    newCode[index] = value;
    setVerificationCode(newCode.join(''));
    
    if (value !== '' && index < 3) {
      verificationInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && verificationCode[index] === '') {
      verificationInputs.current[index - 1].focus();
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/verify-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: formData.email,
          code: verificationCode
        })
      });

      if (response.ok) {
        setIsVerified(true);
        setTimeout(() => {
          setRegistrationSuccess(true);
          setTimeout(() => {
            navigate('/login');
          }, 1);
        }, 4000);
      } else {
        alert('Invalid verification code. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      alert('Error verifying code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = () => {
    setIsGoogleLoading(true);
    window.location.href = `${BASE_URL}/auth/google`;
  };

  // const loadCountryOptions = useCallback(
  //   (inputValue, callback) => {
  //     const options = Country.getAllCountries()
  //       .filter(country => country.name.toLowerCase().includes(inputValue.toLowerCase()))
  //       .map(country => ({
  //         value: country.isoCode,
  //         label: country.name
  //       }));
  //     callback(options.slice(0, 100));
  //   },
  //   []
  // );
  
  // const loadHometownOptions = useCallback(
  //   (inputValue, callback) => {
  //     if (!formData.country || inputValue.length < 2) {
  //       callback([]);
  //       return;
  //     }
  
  //     const options = [];
  //     const states = State.getStatesOfCountry(formData.country);
  
  //     states.forEach(state => {
  //       const cities = City.getCitiesOfState(formData.country, state.isoCode);
  
  //       cities.forEach(city => {
  //         const cityState = `${city.name}, ${state.name}`;
  //         if (cityState.toLowerCase().includes(inputValue.toLowerCase())) {
  //           options.push({
  //             value: cityState,
  //             label: cityState
  //           });
  //         }
  //       });
  
  //       if (state.name.toLowerCase().includes(inputValue.toLowerCase())) {
  //         options.push({
  //           value: state.name,
  //           label: state.name
  //         });
  //       }
  //     });
  
  //     callback(options.slice(0, 100));
  //   },
  //   [formData.country]
  // );
  
  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     height: '38px',
  //     minHeight: '38px',
  //     borderColor: state.isFocused ? '#80bdff' : '#ced4da',
  //     boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null,
  //     '&:hover': {
  //       borderColor: state.isFocused ? '#80bdff' : '#ced4da'
  //     },
  //     backgroundColor: darkMode ? '#3a3a3a' : 'white',
  //     color: darkMode ? '#f0f0f0' : '#333',
  //   }),
  //   valueContainer: (provided) => ({
  //     ...provided,
  //     height: '38px',
  //     padding: '0 6px'
  //   }),
  //   input: (provided) => ({
  //     ...provided,
  //     margin: '0px',
  //     color: darkMode ? '#f0f0f0' : '#333',
  //   }),
  //   indicatorsContainer: (provided) => ({
  //     ...provided,
  //     height: '8px',
  //   }),
  //   placeholder: (provided, state) => ({
  //     ...provided,
  //     display: state.isFocused && !state.hasValue ? 'none' : 'block',
  //     color: darkMode ? '#aaa' : '#999',
  //   }),
  //   singleValue: (provided, state) => ({
  //     ...provided,
  //     display: state.hasValue ? 'block' : 'none',
  //     color: darkMode ? '#f0f0f0' : '#333',
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: darkMode
  //       ? state.isSelected
  //         ? '#4a4a4a'
  //         : state.isFocused
  //         ? '#3a3a3a'
  //         : '#2a2a2a'
  //       : state.isSelected
  //       ? '#e6e6e6'
  //       : state.isFocused
  //       ? '#f0f0f0'
  //       : 'white',
  //     color: darkMode ? '#f0f0f0' : '#333',
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     backgroundColor: darkMode ? '#2a2a2a' : 'white',
  //   }),
  // };

  const handleSpeech = (element) => {
    if (isSpeechEnabled) {
      let speechText = '';
      switch (element.id) {
        case 'fullname':
          speechText = 'Full Name';
          break;
        case 'gender':
          speechText = 'Select Gender';
          break;
        case 'username':
          speechText = 'Username';
          break;
        case 'email':
          speechText = 'Email';
          break;
        case 'password':
          speechText = 'Password';
          break;
        case 'repassword':
          speechText = 'Re-enter Password';
          break;
        case 'dark':
          speechText = 'Dark mode';
          break;
        case 'speech':
          speechText = isSpeechEnabled ? 'Disable Text to Speech' : 'Enable Text to Speech';
          break;
        default:
          speechText = element.innerText || element.value || element.alt || 'Button';
      }
      speak({ text: speechText });
    }
  };

  const handleMouseEnter = (event) => {
    handleSpeech(event.target);
  };

  const handleFocus = (event) => {
    handleSpeech(event.target);
  };
  
  const handleTouch = (event) => {
    handleSpeech(event.target);
  };

  const handleTermsChange = (e) => {
    setAcceptedTerms(e.target.checked);
  };
  
  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };
  
  const termsAndConditions = `
    Welcome to Raahi!

    These terms and conditions outline the rules and regulations for the use of the Raahi app.

    1. Introduction
    By accessing this app, you agree to be bound by these terms and conditions. Do not continue to use Raahi if you do not agree to all of the terms and conditions stated on this page.

    2. License
    Unless otherwise stated, Raahi and/or its licensors own the intellectual property rights for all material on Raahi. All intellectual property rights are reserved. You may access this from Raahi for your personal use subject to restrictions set in these terms and conditions.

    3. User Accounts
    To access certain features of Raahi, you must register and create an account. You are responsible for maintaining the confidentiality of your account information, including your password. You are also responsible for all activities that occur under your account.

    4. User Content
    Users may post content in the app, such as travel plans, journal entries, and social posts. By posting content, you grant Raahi a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, and distribute such content in connection with the operation of the service.

    5. Prohibited Activities
    Users are prohibited from:
    - Posting content that is unlawful, harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.
    - Engaging in any activity that interferes with or disrupts the app’s services.
    - Using the app for any unauthorized or illegal purpose.

    6. Termination
    Raahi reserves the right to terminate or suspend your account and access to the app, without prior notice or liability, for any reason, including breach of these terms.

    7. Limitation of Liability
    Raahi, its directors, employees, and affiliates will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.

    8. Changes to Terms
    Raahi reserves the right to revise these terms at any time. By using this app, you are expected to review these terms regularly to ensure you understand all conditions governing use of this app.

    9. Governing Law
    These terms will be governed by and interpreted in accordance with the laws of the jurisdiction in which Raahi operates.
    `;

const privacyPolicy = `
    1. Introduction
    Welcome to Raahi! At Raahi, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our app and services. By using Raahi, you agree to the terms outlined in this policy.

    2. Information We Collect
    Personal Information:
    - Registration Data: When you sign up for an account, we collect information such as your name and email address.
    - Profile Information: You may provide additional information for your profile, such as preferences.

    Location Data:
    - Location Information: With your permission, we collect and use information about your location to provide location-based services such as itinerary planning and emergency assistance.

    Usage Data:
    - Activity Information: We collect information about your interactions with the app, such as pages viewed, features used, and your actions within the app.

    3. How We Use Your Information
    We use the information we collect for various purposes, including:
    - To Provide and Improve Our Services: To operate and maintain Raahi, including customizing your experience and optimizing our app’s performance.
    - To Communicate with You: To send you updates, notifications, and other information related to your account and our services.
    - To Enhance User Experience: To analyze how users interact with Raahi, allowing us to improve our app and develop new features.
    - For Security and Safety: To protect the rights, property, or safety of Raahi, our users, and others.

    4. Sharing Your Information
    We do not sell your personal information. We may share your information in the following ways:
    - Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as data hosting or analytics.
    - Legal Requirements: We may disclose your information if required by law or in response to valid requests by public authorities.

    5. Your Choices
    - Location Data: You can enable or disable location services through your device settings.
    - Profile Information: You can update or delete your profile information at any time through the app settings.
    - Marketing Communications: You can opt out of receiving marketing emails by following the unsubscribe link in the emails we send.

    6. Data Security
    We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no internet or app-based service can guarantee complete security.

    7. Children's Privacy
    Raahi is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.

    8. Changes to This Privacy Policy
    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of Raahi after the changes take effect will signify your acceptance of the revised policy.
    `;

    const formatTextToHTML = (text) => {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    };

  return (
    <div className={`Signup ${darkMode ? 'dark-mode' : ''} ${registrationSuccess ? 'success' : ''}`}>
      <div className="accessibility-controls3">
        <div className="speech-toggle-container3">
          <div id='speech' className={`speech-toggle3 ${isSpeechEnabled ? 'active' : ''}`} onClick={handleToggleSpeech} onMouseEnter={handleMouseEnter} onFocus={handleFocus}
          onTouchStart={handleTouch}>
            <FaVolumeUp />
          </div>
        </div>
        <div
          className="theme-toggle"
          onClick={toggleDarkMode}
          onMouseEnter={handleMouseEnter}
          onFocus={handleFocus}
          onTouchStart={handleTouch}
          tabIndex="0"
        >
          {darkMode ? <FaSun /> : <FaMoon />}
        </div>
      </div>
      <header className="Signup-header">
        {!isVerifying && !registrationSuccess ? (
          <>
            <span className="SignupTitleContainer" onMouseEnter={handleMouseEnter} onFocus={handleFocus}
            onTouchStart={handleTouch}>
              <h3 className='lt'>Sign up for</h3>
              <h1 className='Title'>Raahi</h1>
            </span>
            <button 
              className={`google-signup ${isGoogleLoading ? 'loading' : ''}`} 
              onClick={handleGoogleSignIn}
              disabled={isGoogleLoading}
              onMouseEnter={handleMouseEnter}
              onFocus={handleFocus}
              onTouchStart={handleTouch}
            >
              {isGoogleLoading ? (
                <div className="google-spinner"></div>
              ) : (
                <>
                  <img src={googleLogo} alt="Google logo" className="google-logo" />
                  <span>Continue with Google</span>
                </>
              )}
            </button>
            <hr className="separator1" />
            <form onSubmit={handleSubmit}>
              <div className="input-container">
                {avatarConfig && (
                  <div className="avatar-container">
                    <Avatar style={{ width: '100px', height: '100px' }} {...avatarConfig} />
                  </div>
                )}
                <input type="text" id="fullname" name="fullname" placeholder="Full Name" required value={formData.fullname} onChange={handleChange} onBlur={handleBlur} onMouseEnter={handleMouseEnter} onFocus={handleFocus}
                onTouchStart={handleTouch} />
              </div>
              <div className="input-container">
                <select id="gender" name="gender" required value={formData.gender} onChange={handleChange} onBlur={handleBlur} onMouseEnter={handleMouseEnter} onFocus={handleFocus}
                onTouchStart={handleTouch}>
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-container">
                <input type="text" id="username" name="username" placeholder="Username" required value={formData.username} onChange={handleChange} onBlur={handleBlur} className={validation.username ? 'error' : ''} onMouseEnter={handleMouseEnter} onFocus={handleFocus}
                onTouchStart={handleTouch}/>
                {validation.username && <span className="validation-message validation-error">{validation.username}</span>}
              </div>
              <div className="input-container">
                <input type="email" id="email" name="email" placeholder="Email" required value={formData.email} onChange={handleChange} onBlur={handleBlur} className={validation.email ? (validation.email === 'This email already exists, please login' ? 'warning' : 'error') : ''} onMouseEnter={handleMouseEnter} onFocus={handleFocus}
                onTouchStart={handleTouch}/>
                {validation.email && <span className={`validation-message ${validation.email === 'This email already exists, please login' ? 'validation-warning' : 'validation-error'}`}>{validation.email}</span>}
              </div>
              <div className="input-container">
                <input type="password" id="password" name="password" placeholder="Password" required value={formData.password} onChange={handleChange} onBlur={handleBlur} className={validation.password ? 'error' : ''} />
                {validation.password && <span className="validation-message validation-error">{validation.password}</span>}
              </div>
              <div className="input-container">
                <input type="password" id="repassword" name="repassword" placeholder="Re-enter Password" required value={formData.repassword} onChange={handleChange} onBlur={handleBlur} className={validation.repassword ? 'error' : ''} />
                {validation.repassword && <span className="validation-message validation-error">{validation.repassword}</span>}
              </div>
              <div className="terms-container">
                <label>
                  <input
                    type="checkbox"
                    checked={acceptedTerms}
                    onChange={handleTermsChange}
                    onMouseEnter={handleMouseEnter}
                    onFocus={handleFocus}
                    onTouchStart={handleTouch}
                  />
                  Accept
                  <span className="link" onClick={() => openModal(termsAndConditions)}>Terms</span>
                  {' '}and{' '}
                  <span className="link" onClick={() => openModal(privacyPolicy)}>Privacy Policy</span>
                </label>
              </div>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Terms and Conditions"
                className={`Modal ${darkMode ? 'dark-mode' : ''}`}
                overlayClassName="Overlay"
              >
                <div className={`modal-content ${darkMode ? 'dark-mode' : ''}`}>
                  <h2>{modalContent === termsAndConditions ? 'Terms and Conditions' : 'Privacy Policy'}</h2>
                  <div className="scrollable-content">
                    {formatTextToHTML(modalContent)}
                  </div>
                  <button onClick={closeModal}>Close</button>
                </div>
              </Modal>
              <button type="submit" disabled={isLoading} onMouseEnter={handleMouseEnter} onFocus={handleFocus}
              onTouchStart={handleTouch}>
                {isLoading ? (
                  <div className="loading-spinner"></div>
                ) : (
                  <strong>SIGN UP</strong>
                )}
              </button>
            </form>
            <p className="login-text" onMouseEnter={handleMouseEnter} onFocus={handleFocus}
            onTouchStart={handleTouch}>Already have an account? <a href="/login" onMouseEnter={handleMouseEnter}><strong>Log in</strong></a></p>
          </>
        ) : isVerifying ? (
          <div className="verification-form">
            <div className="avatar-container">
              <Avatar style={{ width: '100px', height: '100px' }} {...avatarConfig} />
            </div>
            <p className="welcome-text" onMouseEnter={handleMouseEnter}>Welcome to <span className='Title'>Raahi</span> {formData.fullname}!</p>
            {isVerified ? (
              <p className="verified-text" onMouseEnter={handleMouseEnter}>Verified! Please login to continue</p>
            ) : (
              <>
                <p onMouseEnter={handleMouseEnter}>Please enter the 4-digit code sent to your email:</p>
                <form onSubmit={handleVerificationSubmit}>
                  <div className="verification-code-container">
                    {[0, 1, 2, 3].map((index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={verificationCode[index] || ''}
                        onChange={(e) => handleVerificationCodeChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (verificationInputs.current[index] = el)}
                        required
                        disabled={isLoading}
                        onMouseEnter={handleMouseEnter}
                        onFocus={handleFocus}
                        onTouchStart={handleTouch}
                      />
                    ))}
                  </div>
                  <button type="submit" disabled={isLoading} onMouseEnter={handleMouseEnter}>
                    {isLoading ? (
                      <div className="loading-spinner"></div>
                    ) : (
                      'Verify'
                    )}
                  </button>
                </form>
              </>
            )}
          </div>
        ) : (
          <div className="success-message">
            <div className="avatar-container">
              <Avatar style={{ width: '100px', height: '100px' }} {...avatarConfig} />
            </div>
            <p className="welcome-text" onMouseEnter={handleMouseEnter}>Welcome to <span className='Title'>Raahi</span> {formData.fullname}!</p>
            <div><p onMouseEnter={handleMouseEnter}>Your email has been verified. Please login to continue.</p></div>
          </div>
        )}
      </header>
    </div>
  );
}

export default Signup;