import React, { createContext, useState, useContext, useEffect } from 'react';

const SpeechContext = createContext();

export const SpeechProvider = ({ children }) => {
  const [isSpeechEnabled, setIsSpeechEnabled] = useState(false);

  useEffect(() => {
    const storedSpeechEnabled = localStorage.getItem('speechEnabled');
    if (storedSpeechEnabled) {
      setIsSpeechEnabled(JSON.parse(storedSpeechEnabled));
    }
  }, []);

  const toggleSpeech = () => {
    const newSpeechEnabled = !isSpeechEnabled;
    setIsSpeechEnabled(newSpeechEnabled);
    localStorage.setItem('speechEnabled', JSON.stringify(newSpeechEnabled));
  };

  return (
    <SpeechContext.Provider value={{ isSpeechEnabled, toggleSpeech }}>
      {children}
    </SpeechContext.Provider>
  );
};

export const useSpeech = () => useContext(SpeechContext);