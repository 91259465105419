import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import Avatar from 'react-nice-avatar';
import { BASE_URL } from '../config';
import { FaPlane, FaCar, FaShip, FaTrain, FaTools, FaExclamationTriangle, FaUsers, FaSun, FaMoon, FaCompass, FaSave, FaVolumeUp, FaPen, FaComments, FaCamera, FaSpinner } from 'react-icons/fa';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { useSpeechSynthesis } from 'react-speech-kit';
import { useSpeech } from '../SpeechContext';
import Tools from './tools';
import CameraCapture from './CameraCapture';
import Profile from './Profile';
import Social from './Social';
import Journal from './Journal'
import PostEmergency from './PostEmergency';
import MembersEmergency from './MembersEmergency';
import PlanItinerary from './PlanItinerary';
import SavedItinerary from './SavedItinerary';
import Explore from './Explore';

const fetchAPIKeys = async () => {
  try {
      const response = await fetch(`${BASE_URL}/api/keys`, { credentials: 'include' });
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching API keys:', error);
      return {};
  }
};

let genAI;
let GOOGLE_MAPS_API_KEY;

const initializeAPIKeys = async () => {
  const keys = await fetchAPIKeys();
  genAI = new GoogleGenerativeAI(keys.googleGenerativeAI);
  GOOGLE_MAPS_API_KEY = keys.googleMaps;
};

initializeAPIKeys();

function Home() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const [currentPlanIcon, setCurrentPlanIcon] = useState(0);
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const planIcons = [<FaPlane />, <FaCar />, <FaShip />, <FaTrain />];
    const { speak } = useSpeechSynthesis();
    const { isSpeechEnabled, toggleSpeech } = useSpeech();
    const [showTools, setShowTools] = useState(false);
    const [showImageOptions, setShowImageOptions] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [userLocation, setUserLocation] = useState(null);
    const [showProfile, setShowProfile] = useState(false);
    const [showSocial, setShowSocial] = useState(false);
    const [showJournal, setShowJournal] = useState(false);
    const [showEmergency, setShowEmergency] = useState(false);
    const [showMembersEmergency, setShowMembersEmergency] = useState(false);
    const [showPlanItinerary, setShowPlanItinerary] = useState(false);
    const [showSavedItinerary, setShowSavedItinerary] = useState(false);
    const [showExplore, setShowExplore] = useState(false);
    const [hasNearbyEmergencies, setHasNearbyEmergencies] = useState(false);
    const [showLocationPopup, setShowLocationPopup] = useState(false);

    useEffect(() => {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const { latitude, longitude } = position.coords;
                  setUserLocation({ latitude, longitude });
                  updateUserLocation(latitude, longitude);
              },
              (error) => {
                  console.error("Error getting location:", error);
                  setShowLocationPopup(true);
              }
          );
      } else {
          console.log("Geolocation is not supported by this browser.");
          setShowLocationPopup(true);
      }
    }, []);

    useEffect(() => {
      window.history.pushState({ page: "home" }, "");
      const handlePopState = (event) => {
        event.preventDefault();
        window.location.reload();
      };
  
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, []);

    useEffect(() => {
        const checkNearbyEmergencies = async () => {
          try {
            const response = await fetch(`${BASE_URL}/api/nearby-emergencies`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify({ 
                latitude: userLocation?.latitude, 
                longitude: userLocation?.longitude 
              }),
            });
            if (response.ok) {
              const data = await response.json();
              setHasNearbyEmergencies(data.length > 0);
            }
          } catch (error) {
            console.error('Error checking nearby emergencies:', error);
          }
        };
      
        if (userLocation) {
          checkNearbyEmergencies();
        }
    }, [userLocation]);


    const LocationPopup = ({ onRefresh, onIgnore }) => {
      return (
          <div className="location-popup">
              <div className="location-popup-content">
                  <p>Location is required for all features to work correctly. Kindly refresh the page and grant location permission. Make sure your location services are enabled and your browser has permission to use your location.</p>
                  <button onClick={onRefresh}>Refresh</button>
                  <button onClick={onIgnore}>Ignore</button>
              </div>
          </div>
      );
    };

    const handleRefresh = () => {
      window.location.reload();
    };
  
    const handleIgnore = () => {
        setShowLocationPopup(false);
    };

    const updateUserLocation = async (latitude, longitude) => {
    try {
        const response = await fetch(`${BASE_URL}/update-location`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ latitude, longitude }),
        });
        
        if (!response.ok) {
        throw new Error('Failed to update location');
        }
        
        const data = await response.json();
        console.log(data.message);
    } catch (error) {
        console.error('Error updating location:', error);
    }
    };

    const sections = [
        { 
            name: "Plan itinerary", 
            icon: planIcons[currentPlanIcon], 
            className: "plan-itinerary",
            action: () => setShowPlanItinerary(true)
        },
        { 
            name: "Explore", 
            icon: <FaCompass />, 
            action: () => setShowExplore(true)
        },
        {
            name: "Saved Itineraries",
            icon: <FaSave />,
            action: () => setShowSavedItinerary(true)
        },
        { name: "Tools", icon: <FaTools />, action: () => setShowTools(true) },
        { name: "Social", icon: <FaComments />, action: () => setShowSocial(true) },
        { 
            name: "Journal", 
            icon: <FaPen />, 
            action: () => setShowJournal(true) 
        },
        { name: "Post an emergency", icon: <FaExclamationTriangle />, action: () => setShowEmergency(true) },
        {
            name: "Travellers in emergency",
            icon: (
              <div style={{ position: 'relative' }}>
                <FaUsers />
                {hasNearbyEmergencies && (
                  <div className="breathing-dot" />
                )}
              </div>
            ),
            action: () => setShowMembersEmergency(true)
        },
    ];

    const handleCameraCapture = () => {
        setShowCamera(true);
        setShowImageOptions(false);
      };

      const handleProfileClick = () => {
        setShowProfile(true);
        setShowDropdown(false);
      };
      
      const handleUserDataUpdate = (updatedData) => {
        setUserData(updatedData);
      };
      
      
      const handleCapturedImage = (file) => {
        setSelectedImage(URL.createObjectURL(file));
        processImage(file);
        setShowCamera(false);
      };
      

    useEffect(() => {
        document.body.classList.toggle('dark-mode', darkMode);
        const backgroundColor = darkMode ? '#1a1a1a' : '#ffffff';
        document.documentElement.style.setProperty('--background-color', backgroundColor);
    }, [darkMode]);

    useEffect(() => {
        let interval;
        const planBox = document.querySelector('.plan-itinerary');
        
        const startIconChange = () => {
            interval = setInterval(() => {
                setCurrentPlanIcon((prev) => (prev + 1) % planIcons.length);
            }, 500);
        };
    
        const stopIconChange = () => {
            clearInterval(interval);
            setCurrentPlanIcon(0);
        };
    
        if (planBox) {
            planBox.addEventListener('mouseenter', startIconChange);
            planBox.addEventListener('mouseleave', stopIconChange);
        }
    
        return () => {
            if (planBox) {
                planBox.removeEventListener('mouseenter', startIconChange);
                planBox.removeEventListener('mouseleave', stopIconChange);
            }
            clearInterval(interval);
        };
    }, [planIcons.length]);

    const handleTitleClick = () => {
        setShowTools(false);
    };

    const fetchUserData = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setUserData(data);
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(`${BASE_URL}/logout`, {
                method: 'POST',
                credentials: 'include'
            });
            if (response.ok) {
                navigate('/login');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode.toString());
    };

    const CapturedImage = ({ imageUrl, isLoading }) => {
      return (
        <div className="captured-image-container">
          <img src={imageUrl} alt="Captured" className="captured-image" />
          {isLoading && <div className="loading-overlay">
            <FaSpinner className="loading-icon" />
          </div>}
        </div>
      );
    };

    const handleToggleSpeech = () => {
        toggleSpeech();
      };
      
      const handleSpeech = (element) => {
        if (isSpeechEnabled) {
          let speechText = '';
          if (element.className === 'section-name') {
            speechText = element.innerText;
          } else if (element.className === 'Title') {
            speechText = 'Raahi';
          } else if (element.id === 'dark') {
            speechText = 'Dark mode';
          } else if (element.id === 'speech') {
            speechText = 'Disable Text to Speech';
          } else {
            speechText = element.innerText || element.value || element.alt || 'Button';
          }
          speak({ text: speechText });
        }
      };
      
      const handleMouseEnter = (event) => {
        handleSpeech(event.target);
      };
      
      const handleFocus = (event) => {
        handleSpeech(event.target);
      };
      
      const handleTouch = (event) => {
        handleSpeech(event.target);
      };

      const processImage = async (imageFile) => {
        setIsLoading(true);
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
      
        const imageData = await fileToGenerativePart(imageFile);
        const prompt = "Describe this image in detail. Tell the history of the place and everything about it's importance.";
      
        try {
          const result = await model.generateContent([prompt, imageData]);
          const response = await result.response;
          const text = response.text();
          setPopupContent(text);
          setShowPopup(true);
        } catch (error) {
          console.error('Error processing image:', error);
          setPopupContent('An error occurred while processing the image.');
          setShowPopup(true);
        } finally {
          setIsLoading(false);
          setSelectedImage(null); 
        }
      };
      
      
      const fileToGenerativePart = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              inlineData: {
                data: reader.result.split(',')[1],
                mimeType: file.type,
              },
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };
      
     
      const handleCameraClick = () => {
        setShowImageOptions(!showImageOptions);
      };
      
      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
          setSelectedImage(file);
          processImage(file);
        }
      };

      return (
        <div className={`Home ${darkMode ? 'dark-mode' : ''}`}>
            <header className="Home-header">
                <h1 
                    className="Title"
                    onMouseEnter={handleMouseEnter}
                    onClick={handleTitleClick}
                    onFocus={handleFocus}
                    onTouchStart={handleTouch}
                >
                    Raahi
                </h1>
                <div className="header-right">
                    <div className="camera-container">
                        <div 
                        className="camera-icon"
                        onClick={handleCameraClick}
                        onMouseEnter={handleMouseEnter}
                        onFocus={handleFocus}
                        onTouchStart={handleTouch}
                        >
                        <FaCamera />
                        </div>
                        {showImageOptions && (
                        <div className="image-options">
                            <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            id="fileInput"
                            />
                            <label htmlFor="fileInput">Upload from device</label>
                            <button onClick={handleCameraCapture}>Click a picture</button>
                        </div>
                        )}
                    </div>
                    <div className="speech-toggle-container">
                        <div 
                            id='speech' 
                            className={`speech-toggle1 ${isSpeechEnabled ? 'active' : ''}`} 
                            onClick={handleToggleSpeech} 
                            onMouseEnter={handleMouseEnter}
                            onFocus={handleFocus}
                            onTouchStart={handleTouch}
                        >
                            <FaVolumeUp />
                        </div>
                    </div>
                    <div 
                        id='dark' 
                        className="theme-toggle1" 
                        onClick={toggleDarkMode}
                        onMouseEnter={handleMouseEnter}
                        onFocus={handleFocus}
                        onTouchStart={handleTouch}
                    >
                        {darkMode ? <FaSun /> : <FaMoon />}
                    </div>
                    {userData && (
                        <div 
                            className="profile-container"
                            onMouseEnter={handleMouseEnter}
                            onFocus={handleFocus}
                            onTouchStart={handleTouch}
                            
                        >
                            <div className="avatar-wrapper" onClick={toggleDropdown}>
                                <Avatar style={{ width: '60px', height: '60px' }} {...userData.avatarConfig} />
                            </div>
                            {showDropdown && (
                                <div className="dropdown">
                                    <button 
                                   
                                        onMouseEnter={handleMouseEnter}
                                        onFocus={handleFocus}
                                        onTouchStart={handleTouch}
                                        onClick={handleProfileClick}
                                    >
                                        My Profile
                                    </button>
                                    <button 
                                        onClick={handleLogout}
                                        onMouseEnter={handleMouseEnter}
                                        onFocus={handleFocus}
                                        onTouchStart={handleTouch}
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </header>
            <div className="content-container">
                {!showTools && !showSocial && !showJournal && !showEmergency && !showMembersEmergency && !showPlanItinerary && !showSavedItinerary && !showExplore ? (
                    <div className="section-grid">
                        {sections.map((section, index) => (
                            <div
                                key={index}
                                className={`section-box ${section.className || ''}`}
                                onClick={() => {
                                    if (section.action) {
                                        section.action();
                                    } else if (section.path) {
                                        navigate(section.path);
                                    }
                                }}
                                onMouseEnter={handleMouseEnter}
                                onFocus={handleFocus}
                                onTouchStart={handleTouch}
                            >
                                <div className="section-icon">{section.icon}</div>
                                <div className="section-name">{section.name}</div>
                            </div>
                        ))}
                    </div>
                ) : showTools ? (
                    <Tools onBack={() => setShowTools(false)} genAI={genAI}/>
                ) : showSocial ? (
                    <Social onBack={() => setShowSocial(false)} genAI={genAI}/>
                ) : showJournal ? (
                    <Journal onBack={() => setShowJournal(false)} genAI={genAI}/>
                ) : showEmergency ? (
                    <PostEmergency onBack={() => setShowEmergency(false)} genAI={genAI} GOOGLE_MAPS_API_KEY={GOOGLE_MAPS_API_KEY}/>
                ) : showMembersEmergency ? (
                    <MembersEmergency onBack={() => setShowMembersEmergency(false)} userLocation={userLocation} />
                ) :  showSavedItinerary ? (
                    <SavedItinerary onBack={() => setShowSavedItinerary(false)} genAI={genAI} GOOGLE_MAPS_API_KEY={GOOGLE_MAPS_API_KEY}/>
                ) : showExplore ? (
                    <Explore onBack={() => setShowExplore(false)} genAI={genAI}/>
                ) : showPlanItinerary ? (
                    <PlanItinerary onBack={() => setShowPlanItinerary(false)} genAI={genAI} GOOGLE_MAPS_API_KEY={GOOGLE_MAPS_API_KEY}/>
                ) : null}
            </div>
            {showPopup && (
                <div className="popup">
                  <div className="popup-content">
                    <span className="close" onClick={() => setShowPopup(false)}>&times;</span>
                    <p>{popupContent}</p>
                  </div>
                </div>
            )}
            {showLocationPopup && (
              <LocationPopup onRefresh={handleRefresh} onIgnore={handleIgnore} />
            )}
            {isLoading && (
            <div className="loading-overlay">
                <FaSpinner className="loading-icon" />
            </div>
            )}
            {showCamera && (
                <CameraCapture
                  onCapture={handleCapturedImage}
                  onClose={() => setShowCamera(false)}
                />
            )}
            {selectedImage && (
              <CapturedImage imageUrl={selectedImage} isLoading={isLoading} />
            )}
            {showProfile && (
                <Profile
                  userData={userData}
                  onClose={() => setShowProfile(false)}
                  onUpdate={handleUserDataUpdate}
                />
            )
        }
        </div>
        );
}

export default Home;