import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'react-nice-avatar';
import { FaSearch, FaPaperPlane, FaUserPlus, FaCheck, FaTimes, FaClock, FaStar } from 'react-icons/fa';
import './Chats.css';
import { BASE_URL } from '../config';
import io from 'socket.io-client';

function Chats() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [pendingRequests, setPendingRequests] = useState([]);
    const [sentRequests, setSentRequests] = useState([]);
    const [chatUser, setChatUser] = useState(null);
    const [searchResultOpened, setSearchResultOpened] = useState(false);
    const [socket, setSocket] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const fetchUserData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`, {
                credentials: 'include'
            });
            if (response.ok) {
                const userData = await response.json();
                setUserId(userData._id);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        fetchChats();
        fetchPendingRequests();
        fetchSentRequests();
        fetchUserData();

        const newSocket = io(`${BASE_URL}`);
        setSocket(newSocket);

        return () => newSocket.close();
    }, []);

    const rateUser = async (rating) => {
        try {
            if (!chatUser || !chatUser.userId) {
                return;
            }
            
            const response = await fetch(`${BASE_URL}/rate-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userId: chatUser.userId, rating }),
            });
           
            const responseData = await response.json();
            
            if (response.ok) {
                setChatUser(prevUser => ({ ...prevUser, userRating: rating }));
                setChats(prevChats => prevChats.map(chat => 
                    chat.id === selectedChat 
                        ? { ...chat, userRating: rating }
                        : chat
                ));
            } else {
                console.error('Rate error:', responseData);
            }
        } catch (error) {
            console.error('Error rating user:', error);
        }
    };

    const renderStars = (rating, interactive = false) => {
        return [...Array(5)].map((_, index) => (
            <FaStar
                key={index}
                color={index < rating ? "#ffc107" : "#e4e5e9"}
                onClick={() => interactive && rateUser(index + 1)}
                style={interactive ? { cursor: 'pointer' } : {}}
            />
        ));
    };

    const fetchChats = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${BASE_URL}/chats`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                const chatsWithRatings = await Promise.all(data.map(async (chat) => {
                    const ratingResponse = await fetch(`${BASE_URL}/user-rating/${chat.userId}`, {
                        method: 'GET',
                        credentials: 'include'
                    });
                    if (ratingResponse.ok) {
                        const ratingData = await ratingResponse.json();
                        return {
                            ...chat,
                            hasNewMessage: false,
                            rating: chat.averageRating,
                            userRating: ratingData.rating
                        };
                    }
                    return chat;
                }));
                setChats(chatsWithRatings);
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPendingRequests = async () => {
        try {
            const response = await fetch(`${BASE_URL}/friend-requests`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setPendingRequests(data);
            }
        } catch (error) {
            console.error('Error fetching pending requests:', error);
        }
    };

    const fetchSentRequests = async () => {
        try {
            const response = await fetch(`${BASE_URL}/sent-friend-requests`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setSentRequests(data);
            }
        } catch (error) {
            console.error('Error fetching sent requests:', error);
        }
    };

    const handleSearch = async () => {
        try {
            const response = await fetch(`${BASE_URL}/search-users?username=${searchTerm}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                const resultsWithStatus = data.map(user => ({
                    ...user,
                    requestStatus: getPendingRequestStatus(user._id),
                    isFriend: chats.some(chat => chat.id === user._id) 
                }));
                setSearchResults(resultsWithStatus);
                setSearchResultOpened(true);
            }
        } catch (error) {
            console.error('Error searching users:', error);
        }
    };

    const getPendingRequestStatus = (userId) => {
        const sentRequest = sentRequests.find(req => req.recipient.toString() === userId);
        if (sentRequest) return 'pending';
        const receivedRequest = pendingRequests.find(req => req.sender._id.toString() === userId);
        if (receivedRequest) return 'received';
        return 'none';
    };

    const sendFriendRequest = async (userId) => {
        try {
            const response = await fetch(`${BASE_URL}/friend-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ recipientId: userId }),
            });
            if (response.ok) {
                setSentRequests([...sentRequests, { recipient: userId, status: 'pending' }]);
                setSearchResults(searchResults.map(user => 
                    user._id === userId ? { ...user, requestStatus: 'pending' } : user
                ));
            }
        } catch (error) {
            console.error('Error sending friend request:', error);
        }
    };

    const handleFriendRequest = async (requestId, action) => {
        try {
            const response = await fetch(`${BASE_URL}/friend-request/${requestId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ action }),
            });
            if (response.ok) {
                const data = await response.json();
                setPendingRequests(prevRequests => prevRequests.filter(req => req._id !== requestId));
                if (action === 'accept') {
                    const newChat = {
                        id: data.chatId,
                        name: data.friendName,
                        avatarConfig: data.friendAvatarConfig,
                        lastMessage: '',
                        hasNewMessage: false
                    };
                    setChats(prevChats => [...prevChats, newChat]);
                    fetchChats();
                    fetchPendingRequests();
                    fetchSentRequests();
                    fetchUserData();
                }
            }
        } catch (error) {
            console.error('Error handling friend request:', error);
        }
    };

    const openChat = async (chatId) => {
        setSelectedChat(chatId);
        if (socket) {
            socket.emit('join chat', chatId);
        }
        try {
            const response = await fetch(`${BASE_URL}/messages/${chatId}`, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setMessages(data);
                const chat = chats.find(chat => chat.id === chatId);
                setChatUser(chat);
                setChats(prevChats => prevChats.map(c => 
                    c.id === chatId ? { ...c, hasNewMessage: false } : c
                ));
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const sendMessage = async () => {
        if (!newMessage.trim()) return;
    
        const messageContent = newMessage;
        setNewMessage(''); 
    
        try {
            const response = await fetch(`${BASE_URL}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    chatId: selectedChat,
                    content: messageContent
                }),
            });
            if (response.ok) {
                const sentMessage = await response.json();
                
                setMessages(prevMessages => [...prevMessages, {
                    ...sentMessage,
                    isSender: true
                }]);
               
                setChats(chats.map(chat => 
                    chat.id === selectedChat 
                        ? {...chat, lastMessage: messageContent} 
                        : chat
                ));
    
                if (socket) {
                    socket.emit('new message', { ...sentMessage, chatId: selectedChat });
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setNewMessage(messageContent); 
        }
    };

    useEffect(() => {
        if (socket) {
            socket.on('receive message', (message) => {
                if (message.chatId === selectedChat) {
                    setMessages(prevMessages => {
                        if (!prevMessages.some(m => m.id === message.id)) {
                            return [...prevMessages, {
                                ...message,
                                isSender: message.sender === userId
                            }];
                        }
                        return prevMessages;
                    });
                } else {
                    setChats(prevChats => prevChats.map(chat => 
                        chat.id === message.chatId 
                            ? {...chat, lastMessage: message.content, hasNewMessage: true} 
                            : chat
                    ));
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('receive message');
            }
        };
    }, [socket, selectedChat, userId]);

    const closeSearchResult = () => {
        setSearchResultOpened(false); 
        setSearchResults([]);
    };

    return (
        <div className="chats-container">
            <div className="search-bar">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for users by username..."
                />
                <button onClick={handleSearch}><FaSearch /></button>
            </div>
            {searchResultOpened && ( 
                <div className="search-results-overlay">
                    <div className="search-results-content">
                        <div className="search-results-header">
                            <h3>Search Results</h3>
                            <button className="close-button" onClick={closeSearchResult}>X</button>
                        </div>
                        <div className="search-results-list">
                            {searchResults.map(user => (
                                <div key={user._id} className="search-result-item">
                                    <Avatar style={{ width: '40px', height: '40px' }} {...user.avatarConfig} />
                                    <span>{user.fullName}</span>
                                    {user.isFriend && (
                                        <span className="friend-status">Friends!</span>
                                    )}
                                    {!user.isFriend && user.requestStatus === 'none' && (
                                        <button onClick={() => sendFriendRequest(user._id)}><FaUserPlus /> Add Friend</button>
                                    )}
                                    {!user.isFriend && user.requestStatus === 'pending' && (
                                        <button disabled><FaClock /> Pending</button>
                                    )}
                                    {!user.isFriend && user.requestStatus === 'received' && (
                                        <button onClick={() => handleFriendRequest(pendingRequests.find(req => req.sender._id === user._id)._id, 'accept')}><FaCheck /> Accept</button>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="pending-requests">
                <h3><strong>Pending Friend Requests</strong></h3>
                {pendingRequests.length > 0 ? (
                    pendingRequests.map((request) => (
                    <div key={request._id} className="pending-request-item">
                        <Avatar style={{ width: '40px', height: '40px' }} {...request.sender.avatarConfig} />
                        <span>{request.sender.fullName}</span>
                        <button onClick={() => handleFriendRequest(request._id, 'accept')}><FaCheck /> Accept</button>
                        <button onClick={() => handleFriendRequest(request._id, 'reject')}><FaTimes /> Reject</button>
                    </div>
                    ))
                ) : (
                    <p>No Requests</p>
                )}
            </div>
            {isLoading ? (
                <div className="loading-message">Loading...</div>
            ) : (
            <div className="chats-list">
                {chats.map(chat => chat && (
                    <div key={chat.id} className="chat-item" onClick={() => openChat(chat.id)}>
                        {chat.avatarConfig && <Avatar style={{ width: '50px', height: '50px' }} {...chat.avatarConfig} />}
                        <div className="chat-info">
                            <h4>{chat.name}</h4>
                            <p>{chat.lastMessage}</p>
                        </div>
                        {chat.hasNewMessage && <div className="new-message-dot"></div>}
                    </div>
                ))}
                
            </div>
            )}
            {selectedChat && (
                <div className="chat-popup">
                    <div className="chat-content">
                        <div className="chat-header">
                            <button onClick={() => setSelectedChat(null)}>Close</button>
                            {chatUser && (
                                <div className="chat-header-info">
                                    <Avatar style={{ width: '40px', height: '40px' }} {...chatUser.avatarConfig} />
                                    <h3>{chatUser.name}</h3>
                                    <div className="user-rating star-rating">
                                        {renderStars(chatUser.userRating || 0, true)}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="messages-container">
                            {messages.map(message => (
                                <div key={message.id} className={`message ${message.isSender ? 'sent' : 'received'}`}>
                                    {message.content}
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <div className="message-input">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        sendMessage();
                                    }
                                }}
                                placeholder="Type a message..."
                            />
                            <button onClick={sendMessage}><FaPaperPlane /></button>
                        </div>
                    </div>
                </div>
            )}
            
        </div>
    );
}

export default Chats;