import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './complete.css';
import Avatar, { genConfig } from 'react-nice-avatar';
import { BASE_URL } from '../config';
import { FaSun, FaMoon } from 'react-icons/fa';

function CompleteProfile() {
  const [formData, setFormData] = useState({
    gender: '',
    username: ''
  });
  const [userId, setUserId] = useState('');
  const [showWelcome, setShowWelcome] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarConfig, setAvatarConfig] = useState(null);
  const [validation, setValidation] = useState({
    username: ''
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode.toString());
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setUserId(searchParams.get('userId'));
  }, [location]);

  useEffect(() => {
    if (formData.gender) {
      const config = genConfig({
        sex: formData.gender === 'Female' ? 'woman' : 'man',
      });
      setAvatarConfig(config);
    }
  }, [formData.gender]);

  const debouncedUsernameCheck = useCallback((username) => {
    const checkUsername = async () => {
      try {
        const response = await fetch(`${BASE_URL}/check-username?username=${username}`);
        const data = await response.json();
        setValidation((prevValidation) => ({
          ...prevValidation,
          username: data.exists ? 'Username already exists' : ''
        }));
      } catch (error) {
        console.error('Error checking username:', error);
      }
    };

    const timeoutId = setTimeout(checkUsername, 300);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'username' && value) {
      debouncedUsernameCheck(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validation.username) {
      alert('Please fix the errors before submitting');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/complete-profile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          userId, 
          ...formData,
          avatarConfig
        })
      });
      if (response.ok) {
        setShowWelcome(true);
        setTimeout(() => {
          navigate('/home');
        }, 4000);
      } else {
        throw new Error('Failed to complete profile');
      }
    } catch (error) {
      console.error('Error completing profile:', error);
      alert('Error completing profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (showWelcome) {
    return (
      <div id="CompleteProfile" className={`success ${darkMode ? 'dark-mode' : ''}`}>
        <div id="success-message">
          <div id="avatar-container">
            <Avatar style={{ width: '100px', height: '100px' }} {...avatarConfig} />
          </div>
          <p id="welcome-text">Welcome to <span id='Title'>Raahi</span> {formData.username}!</p>
          <p>Redirecting to home...</p>
        </div>
      </div>
    );
  }

  return (
    <div id="CompleteProfile" className={darkMode ? 'dark-mode' : ''}>
      <div className="theme-toggle" onClick={toggleDarkMode}>
        {darkMode ? <FaSun /> : <FaMoon />}
      </div>
      <header id="CompleteProfile-header">
        <span id="CompleteProfileTitleContainer">
          <h3 id='lt'>Complete your profile for</h3>
          <h1 id='Title'>Raahi</h1>
        </span>
        <form onSubmit={handleSubmit}>
          <div id="input-container">
            <select id="gender" name="gender" required value={formData.gender} onChange={handleChange}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div id="input-container">
            <input 
              type="text" 
              id="username" 
              name="username" 
              placeholder="Username" 
              required 
              value={formData.username} 
              onChange={handleChange} 
              className={validation.username ? 'error' : ''}
            />
            {validation.username && <span id="validation-message" className="validation-error">{validation.username}</span>}
          </div>
          <button type="submit" disabled={isLoading || validation.username !== ''}>
            {isLoading ? (
              <div id="loading-spinner"></div>
            ) : (
              <strong>COMPLETE PROFILE</strong>
            )}
          </button>
        </form>
      </header>
    </div>
  );
}

export default CompleteProfile;