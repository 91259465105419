import React, { useState } from 'react';
import Avatar from 'react-nice-avatar';
import { BASE_URL } from '../config';
import './Profile.css';

function Profile({ userData, onClose, onUpdate }) {
 const [editMode] = useState(false);
 const [fullName, setFullName] = useState(userData.fullName);
 const [username, setUsername] = useState(userData.username);
 const [showChangePassword, setShowChangePassword] = useState(false);
 const [newPassword, setNewPassword] = useState('');
 const [confirmPassword, setConfirmPassword] = useState('');
 const [passwordError, setPasswordError] = useState('');

 const handleChangePassword = () => {
   setShowChangePassword(true);
 };


  // const handleSave = async () => {
  //   try {
  //     const response = await fetch(`${BASE_URL}/update-profile`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       credentials: 'include',
  //       body: JSON.stringify({ fullName, username }),
  //     });

  //     if (response.ok) {
  //       onUpdate({ ...userData, fullName, username });
  //       setEditMode(false);
  //     } else {
  //       console.error('Failed to update profile');
  //     }
  //   } catch (error) {
  //     console.error('Error updating profile:', error);
  //   }
  // };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords don't match");
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: userData._id, newPassword }),
        credentials: 'include',
      });
      if (response.ok) {
        alert('Password changed successfully');
        setShowChangePassword(false);
        setNewPassword('');
        setConfirmPassword('');
      } else {
        const error = await response.json();
        setPasswordError(error.message);
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError('An error occurred. Please try again.');
    }
  };
 
  return (
    <div className="profile-overlay">
      <div className="profile-box">
        <button className="close-button1" onClick={onClose}>&times;</button>
        <div className="avatar-container">
          <Avatar style={{ width: '120px', height: '120px' }} {...userData.avatarConfig} />
        </div>
        <div className="profile-details">
          {editMode ? (
            <>
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
              />
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
              />
            </>
          ) : (
            <>
              <h2>{userData.fullName}</h2>
              <p>@{userData.username}</p>
            </>
          )}
          <p>Email: {userData.email}</p>
          <p>Gender: {userData.gender}</p>
          
          {!showChangePassword && (
            <button onClick={handleChangePassword}>Change Password</button>
          )}
          
          {showChangePassword && (
            <form onSubmit={handleSubmitPassword}>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter New Password"
                required
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Re-enter New Password"
                required
              />
              {passwordError && <p className="error-message">{passwordError}</p>}
              <button type="submit">Submit New Password</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
 }
 
 export default Profile;