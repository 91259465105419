import React, { useState} from 'react';
import { FaChevronDown, FaChevronUp, FaArrowLeft, FaSpinner } from 'react-icons/fa';
import './tools.css';
import { useSpeech } from '../SpeechContext';


const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'hi', label: 'Hindi' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    // Add more language options here
];

function Tools({ onBack, genAI }) {
    const [carbonExpanded, setCarbonExpanded] = useState(false);
    const [translatorExpanded, setTranslatorExpanded] = useState(false);
    const [ecoTravelExpanded, setEcoTravelExpanded] = useState(false);
    const [startLocation, setStartLocation] = useState('');
    const [destination, setDestLocation] = useState('');
    const [firstLanguage, setFirstLanguage] = useState('en'); 
    const [secondLanguage, setSecondLanguage] = useState('hi'); 
    const [carbonFootprint, setCarbonFootprint] = useState('');
    const [translationResult, setTranslationResult] = useState(''); 
    const [textToTranslate, setTextToTranslate] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [translating, setTranslating] = useState(false); 
    const { isSpeechEnabled } = useSpeech();

    const handleSpeech = (text) => {
        if (isSpeechEnabled) {
            console.log('Speaking:', text);
        }
    };

    const handleMouseEnter = (event) => {
        handleSpeech(event.target.innerText || event.target.value || event.target.placeholder);
    };

    const calculateCarbonFootprint = async () => {
        setLoading(true);  
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const prompt = `What will be the *direct* one way flight distance and carbon footprint generated if a person flies from "${startLocation}" to "${destination}" and how many trees would be required to clear it in one year? Just give me necessary info, no extra text.`;
            const result = await model.generateContent(prompt);
            const response = await result.response;
            const text = response.text().replace(/\*/g, ''); 
            setCarbonFootprint(text);
        } catch (error) {
            console.error('Error calculating carbon footprint:', error);
            setCarbonFootprint('An error occurred while calculating the carbon footprint.');
        } finally {
            setLoading(false);  
        }
    };

    const translateText = async () => {
        setTranslating(true); 
        try {
            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const prompt = `Translate the following text from ${firstLanguage} to ${secondLanguage}: "${textToTranslate}"`; 
            const result = await model.generateContent(prompt);
            const response = await result.response;
            const text = response.text().replace(/\*/g, ''); 
            setTranslationResult(text);
        } catch (error) {
            console.error('Error translating text:', error);
            setTranslationResult('An error occurred while translating the text.');
        } finally {
            setTranslating(false); 
        }
    };

    return (
        <div className="tools-container">
            <button 
                className="back-button"
                onClick={onBack}
                onMouseEnter={handleMouseEnter}
            >
                <FaArrowLeft /> Back to Home
            </button>
            
            {/* Carbon Footprint Calculator */}
            <div className="tool-box">
                <button 
                    className="tool-header" 
                    onClick={() => setCarbonExpanded(!carbonExpanded)}
                    onMouseEnter={handleMouseEnter}
                >
                    Carbon Footprint Calculator
                    {carbonExpanded ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {carbonExpanded && (
                    <div className="tool-content">
                        <input 
                            type="text" 
                            placeholder="Start Location" 
                            value={startLocation} 
                            onChange={(e) => setStartLocation(e.target.value)}
                            onMouseEnter={handleMouseEnter}
                        />
                        <input 
                            type="text" 
                            placeholder="Destination" 
                            value={destination} 
                            onChange={(e) => setDestLocation(e.target.value)}
                            onMouseEnter={handleMouseEnter}
                        />
                        <button 
                            onClick={calculateCarbonFootprint}
                            onMouseEnter={handleMouseEnter}
                            disabled={loading} 
                        >
                            {loading ? <FaSpinner className="spinner" /> : 'Calculate'}
                        </button>
                        {carbonFootprint && (
                            <div className="carbon-footprint-result">
                                <h3>Carbon Footprint Estimation:</h3>
                                <p>{carbonFootprint}</p>
                                <p>Note: These values are a rough estimate.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            
            {/* Translator */}
            <div className="tool-box">
                <button 
                    className="tool-header" 
                    onClick={() => setTranslatorExpanded(!translatorExpanded)}
                    onMouseEnter={handleMouseEnter}
                >
                    Translator
                    {translatorExpanded ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {translatorExpanded && (
                    <div className="tool-content">
                        <select 
                            value={firstLanguage} 
                            onChange={(e) => setFirstLanguage(e.target.value)}
                            onMouseEnter={handleMouseEnter}
                        >
                            {languageOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <select 
                            value={secondLanguage} 
                            onChange={(e) => setSecondLanguage(e.target.value)}
                            onMouseEnter={handleMouseEnter}
                        >
                            {languageOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <input 
                            type="text" 
                            placeholder="Text to translate" 
                            value={textToTranslate} 
                            onChange={(e) => setTextToTranslate(e.target.value)}
                            onMouseEnter={handleMouseEnter}
                        />
                        <button 
                            onClick={translateText}
                            onMouseEnter={handleMouseEnter}
                            disabled={translating} 
                        >
                            {translating ? <FaSpinner className="spinner" /> : 'Translate'}
                        </button>
                        {translationResult && (
                            <div className="translation-result">
                                <h3>Translation Result:</h3>
                                <p>{translationResult}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            
            {/* Eco-Friendly Traveler Tips */}
            <div className="tool-box">
                <button 
                    className="tool-header" 
                    onClick={() => setEcoTravelExpanded(!ecoTravelExpanded)}
                    onMouseEnter={handleMouseEnter}
                >
                    How to Become an Eco-Friendly Traveler?
                    {ecoTravelExpanded ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {ecoTravelExpanded && (
                    <div className="tool-content">
                        <div className="eco-travel-tips">
                            <p>Becoming an eco-friendly traveler is a rewarding way to minimize your impact on the planet while still experiencing the world. Here are some practical tips:</p>
                            
                            <h3>Before You Go</h3>
                            <ul>
                                <li><strong>Choose your destination wisely:</strong> Consider the environmental impact of your chosen destination. Opt for places with sustainable tourism practices.</li>
                                <li><strong>Pack light:</strong> Reducing luggage weight means less fuel consumption during transportation.</li>
                                <li><strong>Pack eco-friendly essentials:</strong> Bring reusable water bottles, utensils, shopping bags, and toiletries to minimize waste.</li>
                                <li><strong>Offset your carbon footprint:</strong> Consider purchasing carbon offsets to compensate for your travel emissions.</li>
                            </ul>
    
                            <h3>While Traveling</h3>
                            <h4>Transportation:</h4>
                            <ul>
                                <li>Prioritize walking, biking, or public transportation over taxis and rental cars.</li>
                                <li>Choose trains or buses over flights when possible.</li>
                                <li>Opt for electric or hybrid vehicles if renting a car.</li>
                            </ul>
    
                            <h4>Accommodation:</h4>
                            <ul>
                                <li>Choose eco-friendly accommodations with certifications like LEED or Green Key.</li>
                                <li>Reuse towels and linens to conserve water and energy.</li>
                                <li>Be mindful of water and electricity consumption.</li>
                            </ul>
    
                            <h4>Food and Drink:</h4>
                            <ul>
                                <li>Support local businesses and farmers markets.</li>
                                <li>Reduce food waste by ordering only what you can finish.</li>
                                <li>Bring reusable containers for leftovers.</li>
                                <li>Avoid single-use plastics, especially water bottles.</li>
                            </ul>
    
                            <h4>Respect Nature:</h4>
                            <ul>
                                <li>Leave no trace: Pack out all your trash.</li>
                                <li>Respect wildlife and their habitats.</li>
                                <li>Be mindful of your impact on local ecosystems.</li>
                            </ul>
    
                            <h4>Support Local Communities:</h4>
                            <ul>
                                <li>Buy souvenirs from local artisans.</li>
                                <li>Learn about local customs and traditions.</li>
                                <li>Respect local cultures and religions.</li>
                            </ul>
    
                            <h3>After Your Trip</h3>
                            <ul>
                                <li><strong>Share your experiences:</strong> Encourage others to travel responsibly.</li>
                                <li><strong>Continue eco-friendly habits:</strong> Incorporate sustainable practices into your daily life.</li>
                            </ul>
    
                            <p>By following these tips, you can make a significant difference in protecting our planet while enjoying your travels.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Tools;
