import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowLeft, FaPaperPlane } from 'react-icons/fa';
import Avatar from 'react-nice-avatar';
import { BASE_URL } from '../config';
import './MembersEmergency.css';

function MembersEmergency({ onBack, userLocation }) {
    const [userEmergencies, setUserEmergencies] = useState([]);
    const [nearbyEmergencies, setNearbyEmergencies] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedEmergency, setSelectedEmergency] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`, {
                method: 'GET',
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setUserData(data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, []);

    const fetchEmergencies = useCallback(async () => {
        if (!userData || !userLocation) return;
        try {
            setIsLoading(true);
            const response = await fetch(`${BASE_URL}/api/nearby-emergencies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ 
                    latitude: userLocation.latitude, 
                    longitude: userLocation.longitude 
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch emergencies');
            }
            const data = await response.json();
            const userPosted = data.filter(emergency => emergency.user._id === userData._id);
            const nearby = data.filter(emergency => emergency.user._id !== userData._id);
            setUserEmergencies(userPosted);
            setNearbyEmergencies(nearby);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching emergencies:', error);
            setIsLoading(false);
        }
    }, [userLocation, userData]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    useEffect(() => {
        if (userData) {
            fetchEmergencies();
        }
    }, [userData, fetchEmergencies]);

    const handleSendMessage = async () => {
        if (!message.trim() || !selectedEmergency) return;
    
        try {
            const response = await fetch(`${BASE_URL}/api/send-emergency-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    emergencyId: selectedEmergency._id,
                    message: message,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to send message');
            }
            setMessage('');
            setSelectedEmergency(null);
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000); 
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const renderEmergencyCard = (emergency, isUserEmergency = false) => (
        <div key={emergency._id} className="emergency-card">
            <div className="user-info">
                <Avatar style={{ width: '60px', height: '60px' }} {...emergency.user.avatarConfig} />
                <span className="user-name">{emergency.user.fullName}</span>
            </div>
            <p>
                <strong>Location:</strong>{' '}
                <a 
                    href={generateMapUrl(emergency.location)} 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    {emergency.locationText}
                </a>
            </p>
            <p><strong>Contact:</strong> {emergency.contactInfo}</p>
            <p><strong>Description:</strong> {emergency.description}</p>
            {!isUserEmergency && (
                <>
                    <button onClick={() => setSelectedEmergency(emergency)}>Send Message</button>
                    {selectedEmergency && selectedEmergency._id === emergency._id && (
                        <div className="message-form">
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message..."
                            />
                            <button onClick={handleSendMessage}>
                                <FaPaperPlane />
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );

    const generateMapUrl = (location) => {
        if (location && location.coordinates && location.coordinates.length === 2) {
            const [longitude, latitude] = location.coordinates;
            return `https://www.google.com/maps?q=${latitude},${longitude}`;
        }
        return '#'; 
    };

    return (
        <div className="members-emergency-container">
            <div className="emergency-header">
                <button className="back-button" onClick={onBack}>
                    <FaArrowLeft />Go Back
                </button>
                <h2>Emergencies</h2>
            </div>
            {userEmergencies.length > 0 && (
                <div className="user-emergencies">
                    <h3>Your Posted Emergency</h3>
                    {userEmergencies.map(emergency => renderEmergencyCard(emergency, true))}
                </div>
            )}
            <div className="nearby-emergencies">
                <h3>Nearby Posted Emergencies</h3>
                {isLoading ? (
                    <p>Fetching...</p>
                ) : (
                    nearbyEmergencies.map(emergency => renderEmergencyCard(emergency))
                )}
            </div>
            {showPopup && (
                <div className="popup">
                    Message sent!
                </div>
            )}
        </div>
    );
}

export default MembersEmergency;