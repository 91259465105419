import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import googleLogo from '../Assets/google.png';
import { BASE_URL } from '../config';
import { FaSun, FaMoon, FaVolumeUp } from 'react-icons/fa';
import { useSpeechSynthesis } from 'react-speech-kit';
import { useSpeech } from '../SpeechContext';
import InstallPrompt from '../InstallPrompt';

function Login() {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const navigate = useNavigate();
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleForgotPasswordClick = () => {
        setShowForgotPassword(true);
    };

    const handleForgotPasswordClose = () => {
        setShowForgotPassword(false);
        setForgotPasswordEmail('');
        setForgotPasswordMessage('');
        setIsEmailSent(false);
        setIsSubmitting(false);
    };

    const handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch(`${BASE_URL}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: forgotPasswordEmail }),
            });
            const data = await response.json();
            setForgotPasswordMessage(data.message);
            if (response.ok) {
                setIsEmailSent(true);
            }
        } catch (error) {
            console.error('Forgot password error:', error);
            setForgotPasswordMessage('An error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    
    const { speak } = useSpeechSynthesis();
    const { isSpeechEnabled, toggleSpeech } = useSpeech();
    useEffect(() => {
      document.body.classList.toggle('dark-mode', darkMode);
      const backgroundColor = darkMode ? '#1a1a1a' : '#ffffff';
      document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [darkMode]);
  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include'
            });
            if (response.ok) {
                navigate('/home');
            } else {
                const errorData = await response.json();
                alert(errorData.message || 'Login failed. Please try again.');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        setIsGoogleLoading(true);
        window.location.href = `${BASE_URL}/auth/google`;
    };

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode.toString());
    };

    const handleToggleSpeech = () => {
      toggleSpeech();
    };

    const handleSpeech = (element) => {
      if (isSpeechEnabled) {
          let speechText = '';
          if (element.id === 'username') {
              speechText = 'Username or email';
          } else if (element.id === 'password') {
              speechText = 'Password';
          } else if (element.id === 'dark') {
              speechText = 'Dark mode';
          } else if (element.id === 'speech') {
              speechText = 'Disable Text to Speech';
          } else {
              speechText = element.innerText || element.value || element.alt || 'Button';
          }
          speak({ text: speechText });
      }
  };

      const handleMouseEnter = (event) => {
        handleSpeech(event.target);
    };

      const handleFocus = (event) => {
        handleSpeech(event.target);
      };

    const handleTouch = (event) => {
        handleSpeech(event.target);
    };

    return (
        <div className={`Login ${darkMode ? 'dark-mode' : ''}`}>
            <div className="accessibility-controls">
                <div className="speech-toggle-container2">
                    <div id='speech' className={`speech-toggle2 ${isSpeechEnabled ? 'active' : ''}`} onClick={handleToggleSpeech} onMouseEnter={handleMouseEnter}
                    onFocus={handleFocus}
                    onTouchStart={handleTouch}>
                        <FaVolumeUp />
                    </div>
                </div>
                <div id='dark' className="theme-toggle" onClick={toggleDarkMode} onMouseEnter={handleMouseEnter}
                onFocus={handleFocus}
                onTouchStart={handleTouch}>
                    {darkMode ? <FaSun /> : <FaMoon />}
                </div>
            </div>
            <header className="Login-header">
            <InstallPrompt />
                <span className="LoginTitleContainer" onMouseEnter={handleMouseEnter}
                onFocus={handleFocus}
                onTouchStart={handleTouch}>
                    <h3 className='lt'>Login to</h3>
                    <h1 className='Title'>Raahi</h1>
                </span>
                <p className="google-warning">Raahi is not yet verified by Google so Continue with Google won't work.</p>
                <button 
                    className={`google-login ${isGoogleLoading ? 'loading' : ''}`} 
                    onClick={handleGoogleLogin}
                    disabled={isGoogleLoading}
                    onMouseEnter={handleMouseEnter}
                    onFocus={handleFocus}
                    onTouchStart={handleTouch}
                >
                    {isGoogleLoading ? (
                        <div className="google-spinner"></div>
                    ) : (
                        <>
                            <img src={googleLogo} alt="Google logo" className="google-logo" />
                            <span>Continue with Google</span>
                        </>
                    )}
                </button>
                <hr className="separator" />
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Username/Email"
                            required
                            value={formData.username}
                            onChange={handleChange}
                            onMouseEnter={handleMouseEnter}
                            onFocus={handleFocus}
                            onTouchStart={handleTouch}
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            required
                            value={formData.password}
                            onChange={handleChange}
                            onMouseEnter={handleMouseEnter}
                            onFocus={handleFocus}
                            onTouchStart={handleTouch}
                        />
                    </div>
                    <button type="submit" disabled={isLoading} onMouseEnter={handleMouseEnter}
                    onFocus={handleFocus}
                    onTouchStart={handleTouch}>
                        {isLoading ? (
                            <div className="loading-spinner"></div>
                        ) : (
                            <strong>LOGIN</strong>
                        )}
                    </button>
                </form>
                <p className="forgot-password-text" onMouseEnter={handleMouseEnter} onFocus={handleFocus} onTouchStart={handleTouch}>
                    <button onClick={handleForgotPasswordClick} className="forgot-password-button">Forgot Password?</button>
                </p>

                {showForgotPassword && (
                    <div className="forgot-password-popup">
                        <div className="forgot-password-content">
                            <h2>Forgot Password</h2>
                            {!isEmailSent ? (
                                <form onSubmit={handleForgotPasswordSubmit}>
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={forgotPasswordEmail}
                                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                                        required
                                        disabled={isSubmitting}
                                    />
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </form>
                            ) : (
                                <p className="check-email-message">Check your email for further instructions.</p>
                            )}
                            {forgotPasswordMessage && <p>{forgotPasswordMessage}</p>}
                            <button onClick={handleForgotPasswordClose}>Close</button>
                        </div>
                    </div>
                )}
                <p className="signup-text" onMouseEnter={handleMouseEnter}
                onFocus={handleFocus}
                onTouchStart={handleTouch}>
                    Don't have an account? <a href="/signup" onMouseEnter={handleMouseEnter}
                    onFocus={handleFocus}
                    onTouchStart={handleTouch}><strong>Sign up</strong></a>
                </p>
                
            </header>
        </div>
    );
}

export default Login;